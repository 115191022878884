import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_API_URL_ENDPOINT } from "../api/Api";

const Container = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 24px;
  &:hover {
    color: #007bff;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  width: 100%;
  padding: 20px;
  @media (max-width: 600px) {
    padding: 15px;
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ButtonSection = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 12px 20px;
  background: #172169;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #0056b3;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  display: flex;
`;

const TabItem = styled.p`
  margin-left: 10px;
  padding: 5px;
  color: ${({ isActive }) => (isActive ? "#0056b3" : "#007BFF")};
  cursor: pointer;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? "#0056b3" : "transparent")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};

  &:hover {
    border-bottom-color: #007bff;
  }

  &:active {
    color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const Prospect = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isActive, setIsActive] = useState(1);
  const [dots, setDots] = useState("");
  const maxDots = 2;

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < maxDots ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    // Collecte des données du formulaire
    const formData = new FormData(event.target);
    formData.append("type", isActive);

    toast.promise(
      axios.post(`${BASE_API_URL_ENDPOINT}process/prospect/create`, formData),
      {
        loading: "Veuillez patienter...",
        success: (data) => {
          event.target.reset();
          setIsDisabled(false);
          switch (data.data.code) {
            case "001":
              return "Le prospect a été ajouté avec succès !";
            case "002":
              return "Le prospect existe déja !";
            default:
              return "L'opération a réussi !";
          }
        },
        error: "Une erreur est survenue. Veuillez réessayer.",
      }
    );
  };

  return (
    <Container>
      <Header>
        <SearchContainer>
          <BackButton to="/">
            <FaArrowLeft />
            <p style={{ marginLeft: "10px" }}>Ajouter un prospect</p>
          </BackButton>
          <Tab>
            <TabItem isActive={isActive === 1} onClick={() => setIsActive(1)}>
              Entreprise
            </TabItem>
            <TabItem isActive={isActive === 2} onClick={() => setIsActive(2)}>
              Particulier
            </TabItem>
          </Tab>
        </SearchContainer>
      </Header>
      {isActive === 1 ? (
        <Form id="entreprise" onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="company_name">Nom de l'entreprise</Label>
            <Input
              type="text"
              id="company_name"
              name="company_name"
              placeholder="Nom de l'entreprise"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="company_adress">Adresse</Label>
            <Input
              type="text"
              id="company_adress"
              name="company_adress"
              placeholder="Adresse"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="company_phone_number">Numéro de téléphone</Label>
            <Input
              type="tel"
              id="company_phone_number"
              name="company_phone_number"
              placeholder="Numéro de téléphone"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="company_mail">Adresse mail</Label>
            <Input
              type="email"
              id="company_mail"
              name="company_mail"
              placeholder="Adresse mail"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="rccm">RCCM</Label>
            <Input
              type="text"
              id="rccm"
              name="rccm"
              placeholder="RCCM"
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="impot">Numéro d'impot</Label>
            <Input
              type="text"
              id="impot"
              name="impot"
              placeholder="Numéro d'impot"
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="contact_name">Personne à contacter</Label>
            <Input
              type="text"
              id="contact_name"
              name="contact_name"
              placeholder="Personne à contacter"
              required
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="contact_position">Fonction</Label>
            <Input
              type="text"
              id="contact_position"
              name="contact_position"
              placeholder="Fonction"
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="contact_phone_number">Téléphone</Label>
            <Input
              type="tel"
              id="contact_phone_number"
              name="contact_phone_number"
              placeholder="Téléphone"
              required
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="contact_mail">Adresse mail</Label>
            <Input
              type="email"
              id="contact_mail"
              name="contact_mail"
              placeholder="Adresse mail"
              autoComplete="off"
            />
          </FormGroup>
        </Form>
      ) : (
        <Form id="particulier" onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="client_name">Nom du particulier</Label>
            <Input
              type="text"
              id="client_name"
              name="client_name"
              placeholder="Nom du particulier"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="client_adress">Adresse</Label>
            <Input
              type="text"
              id="client_adress"
              name="client_adress"
              placeholder="Adresse complète"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="client_phone_number_1">Numéro de téléphone</Label>
            <Input
              type="tel"
              id="client_phone_number_1"
              name="client_phone_number_1"
              placeholder="Numéro de téléphone"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="client_phone_number_2">
              Numéro de téléphone sécondaire
            </Label>
            <Input
              type="tel"
              id="client_phone_number_2"
              name="client_phone_number_2"
              placeholder="Numéro de téléphone sécondaire"
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="client_mail">Adresse Mail</Label>
            <Input
              type="email"
              id="client_mail"
              name="client_mail"
              placeholder="Adresse mail"
              autoComplete="off"
            />
          </FormGroup>
        </Form>
      )}
      <ButtonSection>
        <ButtonContainer>
          <Button
            type="submit"
            form={isActive === 1 ? "entreprise" : "particulier"}
            disabled={isDisabled}
          >
            {`${isDisabled ? `.${dots}` : `Enregistrer`}`}
          </Button>
        </ButtonContainer>
      </ButtonSection>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default Prospect;
