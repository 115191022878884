import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  FaEye,
  FaEdit,
  FaCheck,
  FaTrash,
  FaArrowLeft,
  FaHome,
} from "react-icons/fa";
import { useParams, Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_API_DOCUMENT, BASE_API_URL_ENDPOINT } from "../api/Api";
import CloturePopUp from "../components/CloturePopUp";
import UpdateSinistrePopUp from "../components/UpdateSinistrePopUp";
import { formatDate } from "../helpers/functions";
import DataTable from "../components/DataTable";
import AddPjPopUp from "../components/AddPjPopUp";
import CommentaireComponent from "../components/CommentaireComponent";
import PjSinistrePopUp from "../components/PjSinistrePopUp";
import CommentaireSinistre from "../components/CommentaireSinistre";

const Container = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 24px;
  &:hover {
    color: #007bff;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Card = styled.div`
  width: 100%;
  .card {
    margin: 20px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .icons {
    display: flex;
    gap: 10px;
  }
  .btn-rounded {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }
  .text-info {
    color: #17a2b8;
  }
  .text-danger {
    color: #dc3545;
  }
  .text-success {
    color: #28a745;
  }
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 150px;
  }
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
  }
  .highlight-input {
    background-color: #2255a4;
    color: white;
  }
`;

const Tab = styled.div`
  display: flex;
`;

const TabItem = styled.p`
  margin-left: 10px;
  padding: 5px;
  color: ${({ isActive }) => (isActive ? "#0056b3" : "#007BFF")};
  cursor: pointer;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? "#0056b3" : "transparent")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};

  &:hover {
    border-bottom-color: #007bff;
  }

  &:active {
    color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  background-color: #172169;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const SinistreDetail = () => {
  const isLoadingRef = useRef(false);
  const [filterValue, setFilterValue] = useState("all");
  const [isOpenPj, setIsOpenPj] = useState(false);
  const [client, setClient] = useState({});
  const [piecesJointes, setPiecesJointes] = useState([]);
  const [commentaires, setCommentaires] = useState([]);
  const [isActive, setIsActive] = useState(1);
  const [sinistre, setSinistre] = useState({});
  const [isUpdated, setIsUpdated] = useState(0);
  const [isClotureOpen, setIsClotureOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const { sinistre_id, client_id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (isLoadingRef.current) return;
      isLoadingRef.current = true;

      try {
        const response = await axios.get(
          `${BASE_API_URL_ENDPOINT}detail/sinistre/${sinistre_id}/${client_id}`
        );
        if (response.data.state) {
          setSinistre(response.data.data);
          setClient(response.data.data.client_item);
          setPiecesJointes(response.data.data.piece_jointes);
          setCommentaires(response.data.data.commentaires);
          toast.success("Chargement effectué !");
        }
      } catch (error) {
        toast.error("Une erreur est survenue. veuillez réessayer.");
      } finally {
        isLoadingRef.current = false;
      }
    };

    fetchData();
  }, [isUpdated]);

  const deleteSinistre = () => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce sinistre ? "
    );

    if (confirmation) {
      axios
        .get(`${BASE_API_URL_ENDPOINT}delete/sinistre/${sinistre_id}`)
        .then((response) => {
          if (response.data.state) {
            toast.success("sinistre supprimé avec succès.");
            navigate(`/client/${client_id}`);
          }
        })
        .catch((error) => {
          toast.error("Erreur lors de la suppression du sinistre:", error);
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nom",
        accessor: "name",
      },
      {
        Header: "Détails",
        accessor: "detail",
        Cell: ({ row }) =>
          userData.rights.read ? (
            <a
              href={`${BASE_API_DOCUMENT}${row.original.path}`}
              target="_blank"
            >
              <FaEye />
            </a>
          ) : (
            <></>
          ),
      },
      {
        Header: "Supprimer",
        accessor: "delete",
        Cell: ({ row }) =>
          userData.rights.delete ? (
            <Link
              onClick={() => {
                deletePj(row.index);
              }}
            >
              <FaTrash color="#DA542E" />
            </Link>
          ) : (
            <></>
          ),
      },
    ],
    [piecesJointes]
  );
  
  const deletePj = (index) => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cette pièce jointe ? "
    );

    if (confirmation) {
      axios
        .get(
          `${BASE_API_URL_ENDPOINT}delete/pj/sinistre/${client_id}/${sinistre_id}/${index}`
        )
        .then((response) => {
          // Vérifiez si la suppression a réussi
          if (response.data.state) {
            toast.success("Pièce jointe supprimée avec succès.");
            setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
          } else {
            toast.error("La suppression a échoué.");
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression:", error);
          toast.error("Erreur lors de la suppression de la pièce jointe.");
        });
    }
  };

  return (
    <Container>
      <Card className="card">
        <div className="card-body">
          <div className="header">
            <BackButton to={`/client/${client_id}`}>
              <FaArrowLeft />
              {client.type != undefined ? (
                <p style={{ marginLeft: "10px" }}>
                  {client.type === 1
                    ? `Sinistre (${sinistre.type_police} - ${sinistre.numero_police}) ${client.content_json.company_name} (Entreprise)`
                    : `Sinistre (${sinistre.type_police} - ${sinistre.numero_police}) ${client.content_json.client_name} (Particulier)`}
                </p>
              ) : (
                <></>
              )}
            </BackButton>
            <div className="icons">
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setIsDetailOpen(!isDetailOpen)}
                title="Consulter"
                className="text-info btn-rounded"
              >
                {!isDetailOpen ? <FaEye /> : <FaHome />}
              </a>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setIsUpdateOpen(true)}
                title="Modifier le sinistre"
                className="text-info btn-rounded"
              >
                <FaEdit />
              </a>
              {sinistre.statut_sinistre === "En cours" && (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsClotureOpen(true)}
                  title="Modifier le statut"
                  className="text-success btn-rounded"
                >
                  <FaCheck />
                </a>
              )}
              {userData.rights.delete && (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={deleteSinistre}
                  title="Supprimer"
                  className="text-danger btn-rounded"
                  id="delete_contrat"
                >
                  <FaTrash />
                </a>
              )}
            </div>
          </div>
          {!isDetailOpen ? (
            <>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="type_police">Type police</label>
                  <input
                    type="text"
                    name="type_police"
                    value={sinistre.type_police || ""}
                    readOnly
                    className="highlight-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="numero_police">Numéro police</label>
                  <input
                    type="text"
                    name="numero_police"
                    value={sinistre.numero_police || ""}
                    readOnly
                    className="highlight-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="numero_sinistre">Numéro sinistre</label>
                  <input
                    type="text"
                    name="numero_sinistre"
                    value={sinistre.numero_sinistre || ""}
                    readOnly
                    className="highlight-input"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="date_sinistre">Date sinistre</label>
                  <input
                    type="text"
                    name="date_sinistre"
                    value={formatDate(sinistre.date_sinistre) || ""}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="date_declaration">Déclaration</label>
                  <input
                    type="text"
                    name="date_declaration"
                    value={formatDate(sinistre.date_declaration) || ""}
                    readOnly
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="statut_sinistre">Statut</label>
                  <input
                    type="text"
                    name="statut_sinistre"
                    value={sinistre.statut_sinistre || ""}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="nom_assureur">Assureur</label>
                  <input
                    type="text"
                    name="nom_assureur"
                    value={sinistre.nom_assureur || ""}
                    readOnly
                  />
                </div>
              </div>

              <div className="border-top"></div>
            </>
          ) : (
            <>
              <SearchContainer>
                <BackButton></BackButton>
                <Tab>
                  <TabItem
                    isActive={isActive === 1}
                    onClick={() => setIsActive(1)}
                  >
                    Pièces jointes
                  </TabItem>
                  <TabItem
                    isActive={isActive === 2}
                    onClick={() => setIsActive(2)}
                  >
                    Commentaires
                  </TabItem>
                </Tab>
              </SearchContainer>

              {isActive === 1 ? (
                <>
                  <ButtonContainer>
                    <Button
                      onClick={() => {
                        setIsOpenPj(true);
                      }}
                    >
                      Ajoutez une pièce jointes
                    </Button>
                  </ButtonContainer>
                  <DataTable
                    columns={columns}
                    data={piecesJointes}
                    filterValue={filterValue}
                    filterFieldIndex={0}
                    onFilterChange={setFilterValue}
                  />
                </>
              ) : (
                <CommentaireSinistre
                  setIsUpdated={setIsUpdated}
                  commentaires={commentaires}
                  clientId={client_id}
                  sinistreId={sinistre_id}
                />
              )}
              <PjSinistrePopUp
                isOpen={isOpenPj}
                setIsOpen={setIsOpenPj}
                setIsUpdated={setIsUpdated}
                clientId={client_id}
                sinistreId={sinistre_id}
              />
            </>
          )}
        </div>
        <Toaster position="top-center" reverseOrder={false} />
      </Card>
      <CloturePopUp
        isOpen={isClotureOpen}
        setIsOpen={setIsClotureOpen}
        setIsUpdated={setIsUpdated}
        sinistreId={sinistre_id}
        clientId={client_id}
      />
      <UpdateSinistrePopUp
        isOpen={isUpdateOpen}
        setIsOpen={setIsUpdateOpen}
        setIsUpdated={setIsUpdated}
        sinistre={sinistre}
        clientId={client_id}
        sinistreId={sinistre_id}
      />
    </Container>
  );
};

export default SinistreDetail;
