import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_API_URL_ENDPOINT } from "../api/Api";

// Styled Components
const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SelectStyled = styled(Select)`
  .react-select__control {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;

  .react-datepicker__input-container {
    width: 100%;
  }
`;

const SubmitButton = styled.button`
  background-color: #172169;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  grid-column: span 3;
  &:hover {
    background-color: #0e1940;
  }
`;

const AddContratPopUp = ({
  isOpen,
  setIsOpen,
  client,
  setIsUpdated,
  garanties,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [dots, setDots] = useState("");
  const maxDots = 2;
  // États pour les champs du formulaire
  const [primeNette, setPrimeNette] = useState();
  const [primeBrute, setPrimeBrute] = useState();
  const [accessoires, setAccessoires] = useState();
  const [tva, setTva] = useState();
  const [fraisSneca, setFraisSneca] = useState();
  const [fraisArca, setFraisArca] = useState();
  const [tauxCommission, setTauxCommission] = useState(null);
  const [commission, setCommission] = useState(0);
  const [tvaCommission, setTvaCommission] = useState(0);
  const [effet, setEffet] = useState(null);
  const [echeance, setEcheance] = useState(null);
  const [selectedGaranties, setSelectedGaranties] = useState([]);
  const [selectedAssureur, setSelectedAssureur] = useState(null);

  // Fonction pour rafraîchir la commission
  const refreshTauxCommission = () => {
    let commissionRate = tauxCommission ? parseFloat(tauxCommission.value) : 0;

    // Gérer le cas des NaN
    commissionRate = isNaN(commissionRate) ? 0 : commissionRate;

    const calculatedCommission = primeNette * (commissionRate / 100);
    const calculatedTvaCommission = calculatedCommission * 0.16;

    setCommission(calculatedCommission.toFixed(2));
    setTvaCommission(calculatedTvaCommission.toFixed(2));
  };

  // Fonction pour calculer la prime brute
  const calculatePrimeBrute = () => {
    const safeParse = (value) => (isNaN(value) ? 0 : value); // Fonction pour gérer NaN

    const calculatedPrimeBrute =
      safeParse(primeNette) +
      safeParse(accessoires) +
      safeParse(fraisArca) +
      safeParse(fraisSneca) +
      safeParse(tva);

    setPrimeBrute(calculatedPrimeBrute.toFixed(2));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < maxDots ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  // Effets pour recalculer la commission et la prime brute quand les valeurs changent
  useEffect(() => {
    refreshTauxCommission();
  }, [tauxCommission, primeNette]);

  useEffect(() => {
    calculatePrimeBrute();
  }, [primeNette, accessoires, fraisArca, fraisSneca, tva]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    const formData = new FormData(event.target);
    formData.append("client_id", client.id);
    formData.append(
      "garanties",
      JSON.stringify(selectedGaranties.map((garantie) => garantie.value))
    );

    axios
      .post(`${BASE_API_URL_ENDPOINT}process/contrat/create`, formData)
      .then((response) => {
        const { code } = response.data;

        toast.success(
          code === "001"
            ? "Le contrat a été ajouté avec succès !"
            : code === "002"
            ? "Le contrat existe déjà !"
            : "L'opération a réussi !"
        );
        setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
        setIsOpen(false);
        setSelectedGaranties([]);
        setEffet(null);
        setEcheance(null);
        setPrimeNette();
        setFraisArca();
        setFraisSneca();
        setAccessoires();
        setTva();
        setPrimeBrute();
        setSelectedAssureur(null);
        setCommission(0);
        setTvaCommission(0);
        setTauxCommission(null);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsDisabled(false);
        setIsOpen(false);
        setSelectedGaranties([]);
        setEffet(null);
        setEcheance(null);
        setPrimeNette();
        setFraisArca();
        setFraisSneca();
        setAccessoires();
        setTva();
        setPrimeBrute();
        setSelectedAssureur(null);
        setCommission(0);
        setTvaCommission(0);
        setTauxCommission(null);
        event.target.reset();
      })
      .finally(() => {
        setIsDisabled(false);
        setIsOpen(false);
        setSelectedGaranties([]);
        setEffet(null);
        setEcheance(null);
        setPrimeNette();
        setFraisArca();
        setFraisSneca();
        setAccessoires();
        setTva();
        setPrimeBrute();
        setSelectedAssureur(null);
        setCommission(0);
        setTvaCommission(0);
        setTauxCommission(null);
        event.target.reset();
      });
  };

  const assureurs = [
    { value: "SUNU S.A", label: "SUNU S.A" },
    { value: "RAWSUR S.A", label: "RAWSUR S.A" },
    { value: "RAWSUR LIFE S.A", label: "RAWSUR LIFE S.A" },
    { value: "ACTIVA VIE S.A", label: "ACTIVA VIE S.A" },
    { value: "MAYFAIR S.A", label: "MAYFAIR S.A" },
    { value: "ACTIVA S.A", label: "ACTIVA S.A" },
    { value: "SFA S.A", label: "SFA S.A" },
    { value: "SONAS S.A", label: "SONAS S.A" },
    { value: "AFRISSUR S.A", label: "AFRISSUR S.A" },
  ];

  const tauxCommissionOptions = [
    { value: "5", label: "5%" },
    { value: "10", label: "10%" },
    { value: "12.5", label: "12.5%" },
    { value: "15", label: "15%" },
    { value: "20", label: "20%" },
  ];

  const garantiesOptions = garanties.map((garantie) => ({
    value: garantie.nom,
    label: garantie.nom,
  }));

  return (
    <Popup
      open={isOpen}
      onClose={() => setIsOpen(false)}
      modal
      contentStyle={{
        width: "80%",
        maxWidth: "90%",
        padding: "20px",
        border: "none",
        overflow: "auto",
        height: "85%",
      }}
    >
      <form id="form" onSubmit={handleFormSubmit} encType="multipart/form-data">
        <FormContainer>
          <FormGroup>
            <Label htmlFor="branche">Branche</Label>
            <Input type="text" name="branche" required />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="police">Police</Label>
            <Input type="text" name="numero_police" required />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="type">Type</Label>
            <Input type="text" name="type_police" />
          </FormGroup>
          <FormGroup style={{ gridColumn: "span 3" }}>
            <Label htmlFor="garanties">Garanties</Label>
            <SelectStyled
              options={garantiesOptions}
              isMulti
              value={selectedGaranties}
              onChange={setSelectedGaranties}
              placeholder="Sélectionnez des garanties"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="effet">Effet</Label>
            <StyledDatePicker
              selected={effet}
              id="date_debut"
              name="date_debut"
              onChange={(date) => setEffet(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Sélectionner une date"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="echeance">Échéance</Label>
            <StyledDatePicker
              selected={echeance}
              id="date_fin"
              name="date_fin"
              onChange={(date) => setEcheance(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Sélectionner une date"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="prime_nette">Prime nette</Label>
            <Input
              type="number"
              id="prime_nette"
              name="prime_nette"
              value={primeNette}
              onChange={(e) => setPrimeNette(parseFloat(e.target.value) || 0)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="frais_arca">Frais ARCA</Label>
            <Input
              type="number"
              id="frais_arca"
              name="frais_arca"
              value={fraisArca}
              onChange={(e) => setFraisArca(parseFloat(e.target.value) || 0)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="frais_sneca">Frais SNECA</Label>
            <Input
              type="number"
              id="frais_sneca"
              name="frais_sneca"
              value={fraisSneca}
              onChange={(e) => setFraisSneca(parseFloat(e.target.value) || 0)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="accessoires">Accessoires</Label>
            <Input
              type="number"
              id="accessoires"
              name="accessoires"
              value={accessoires}
              onChange={(e) => setAccessoires(parseFloat(e.target.value) || 0)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="tva">TVA</Label>
            <Input
              type="number"
              id="tva"
              name="tva"
              value={tva}
              onChange={(e) => setTva(parseFloat(e.target.value) || 0)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="prime_brute">Prime brute</Label>
            <Input
              type="text"
              id="prime_brute"
              name="prime_brute"
              value={primeBrute}
              readOnly
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="assureur">Assureur</Label>
            <SelectStyled
              options={assureurs}
              id="nom_assureur"
              name="nom_assureur"
              value={selectedAssureur}
              onChange={setSelectedAssureur}
              placeholder="Sélectionnez un assureur"
              isClearable
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="taux_commission">Taux de commission</Label>
            <SelectStyled
              options={tauxCommissionOptions}
              value={tauxCommission}
              onChange={setTauxCommission}
              placeholder="Sélectionnez le taux de commission"
              isClearable
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="commission">Commission</Label>
            <Input
              type="text"
              id="commission"
              name="commission"
              value={isNaN(commission) ? 0 : commission}
              readOnly
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="tva_commission">TVA sur commission</Label>
            <Input
              type="text"
              id="tva_commission"
              name="tva_commission"
              value={isNaN(tvaCommission) ? 0 : tvaCommission}
              readOnly
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="frais_gestion">Frais de gestion</Label>
            <Input type="number" name="frais_gestion" required />
          </FormGroup>
          <FormGroup style={{ gridColumn: "span 3" }}>
            <Label htmlFor="documents">Documents</Label>
            <Input type="file" name="documents[]" accept="*" multiple />
            <div id="fileNames"></div>
          </FormGroup>
          <SubmitButton type="submit" form="form" disabled={isDisabled}>
            {`${isDisabled ? `.${dots}` : `Enregistrer le contrat`}`}
          </SubmitButton>
        </FormContainer>
      </form>
      <Toaster position="top-center" reverseOrder={false} />
    </Popup>
  );
};

export default AddContratPopUp;
