import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_API_URL_ENDPOINT } from "../api/Api";

const Container = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 24px;
  &:hover {
    color: #007bff;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  width: 100%;
  padding: 20px;
  @media (max-width: 600px) {
    padding: 15px;
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ButtonSection = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 12px 20px;
  background: #172169;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #0056b3;
  }
`;

const User = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [dots, setDots] = useState("");
  const maxDots = 2;

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < maxDots ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    // Collecte des données du formulaire
    const formData = new FormData(event.target);

    toast.promise(
      axios.post(`${BASE_API_URL_ENDPOINT}process/user/create`, formData),
      {
        loading: "Veuillez patienter...",
        success: (data) => {
          alert(JSON.stringify(data.data));
          event.target.reset();
          setIsDisabled(false);
          switch (data.data.code) {
            case "001":
              return "L'utilisateur a été ajouté avec succès !";
            case "002":
              return "L'utilisateur existe déja !";
            default:
              return "L'opération a réussi !";
          }
        },
        error: "Une erreur est survenue. Veuillez réessayer.",
      }
    );
    setIsDisabled(false);
  };

  return (
    <Container>
      <Header>
        <BackButton to="/">
          <FaArrowLeft />
          <p style={{ marginLeft: "10px" }}>Ajouter un utilisateur</p>
        </BackButton>
      </Header>
      <Form id="form" onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="username">Nom d'utilisateur</Label>
          <Input
            type="text"
            id="username"
            name="username"
            placeholder="Nom d'utilisateur"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="complete_name">Nom complet</Label>
          <Input
            type="text"
            id="complete_name"
            name="complete_name"
            placeholder="Nom complet"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="mail">Email</Label>
          <Input
            type="email"
            id="mail"
            name="mail"
            placeholder="Adresse mail"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password">Mot de passe</Label>
          <Input
            type="password"
            id="password"
            name="password"
            placeholder="Mot de passe"
            required
            autoComplete="off"
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="role">Rôle</Label>
          <Select id="role" name="role" required>
            <option value="">Sélectionnez un rôle</option>
            <option value="1">Administrateur</option>
            <option value="2">Agent</option>
          </Select>
        </FormGroup>
      </Form>
      <ButtonSection>
        <ButtonContainer>
          <Button type="submit" form="form" disabled={isDisabled}>
            {`${isDisabled ? `.${dots}` : `Enregistrer`}`}
          </Button>
        </ButtonContainer>
      </ButtonSection>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default User;
