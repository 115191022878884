import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styled from "styled-components";

// Composants stylisés
const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  max-width: 600px; /* Ajuste la largeur du popup si nécessaire */
`;

const CardBody = styled.div`
  padding: 20px;
`;

const CardTitle = styled.h4`
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-weight: 600;
`;

const ChatBox = styled.div`
  max-height: 475px; /* Limite la hauteur totale */
  overflow-y: auto; /* Active le scroll vertical */
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const ChatList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ChatItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const ChatImg = styled.div`
  margin-right: 10px;
`;

const ChatContent = styled.div`
  flex-grow: 1;
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const UserName = styled.h6`
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
`;

const MessageBox = styled.div`
  background-color: #e3f2fd;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
  word-break: break-word;
`;

const ChatTime = styled.span`
  font-size: 0.875rem;
  color: #9e9e9e;
`;

const CommentPopUp = ({ isOpen, setIsOpen, commentaires }) => {
  // Fonction pour fermer le popup
  const closeModal = () => setIsOpen(false);

  return (
    <Popup open={isOpen} onClose={closeModal} modal>
      <Card>
        <CardBody>
          <ChatBox>
            <ChatList>
              {commentaires.map((commentaire, index) => {
                const date = new Date(commentaire.date);
                const options = {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                };
                const formattedDate = date.toLocaleDateString("fr-FR", options);

                return (
                  <ChatItem key={index}>
                    <ChatImg>
                      <UserImage
                        src="../../assets/images/users/1.jpg"
                        alt="user"
                      />
                    </ChatImg>
                    <ChatContent>
                      <UserName>{commentaire.user.data.complete_name}</UserName>
                      <MessageBox>{commentaire.commentaire}</MessageBox>
                      <ChatTime>{formattedDate}</ChatTime>
                    </ChatContent>
                  </ChatItem>
                );
              })}
            </ChatList>
          </ChatBox>
        </CardBody>
        <CardBody style={{ borderTop: "1px solid #e0e0e0" }}>
          {/* Contenu supplémentaire si nécessaire */}
        </CardBody>
      </Card>
    </Popup>
  );
};

export default CommentPopUp;
