import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  FaEye,
  FaEdit,
  FaTrash,
  FaArrowLeft,
  FaFile,
  FaSync,
  FaHistory,
} from "react-icons/fa";
import { useParams, Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import Select from "react-select";
import axios from "axios";
import { BASE_API_DOCUMENT, BASE_API_URL_ENDPOINT } from "../api/Api";
import { formatDate, parseDate } from "../helpers/functions";
import DataTable from "../components/DataTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AvenantPopUp from "../components/AvenantPopUp";
import AvenantHPopUp from "../components/AvenantHPopUp";

const Container = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 24px;
  &:hover {
    color: #007bff;
  }
`;

const Card = styled.div`
  width: 100%;
  .card {
    margin: 20px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .icons {
    display: flex;
    gap: 10px;
  }
  .btn-rounded {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }
  .text-info {
    color: #17a2b8;
  }
  .text-danger {
    color: #dc3545;
  }
  .text-success {
    color: #28a745;
  }
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 150px;
  }
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
  }
  .highlight-input {
    background-color: #2255a4;
    color: white;
  }
`;

const Button = styled.button`
  background-color: #172169;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SelectStyle = styled(Select)`
  .react-select__control {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const SelectStyled = styled.div`
  border: 2px solid #4a90e2;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;

  div {
    margin-bottom: 8px;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    padding: 4px;
    border-bottom: 1px dashed #ccc;
  }

  div:last-child {
    border-bottom: none;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;

  .react-datepicker__input-container {
    width: 100%;
  }
`;

const SubmitButton = styled.button`
  background-color: #172169;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  grid-column: span 3;
  &:hover {
    background-color: #0e1940;
  }
`;

const ContratDetail = () => {
  const isLoadingRef = useRef(false);
  const [filterValue, setFilterValue] = useState("all");
  const [isOpenPj, setIsOpenPj] = useState(false);
  const [client, setClient] = useState({});
  const [contrat, setContrat] = useState({});
  const [garanties, setGaranties] = useState([]);
  const [avenants, setAvenants] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [isActive, setIsActive] = useState(0);
  const [sinistre, setSinistre] = useState({});
  const [isUpdated, setIsUpdated] = useState(0);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isAvenantHOpen, setIsAvenantHOpen] = useState(false);
  const [avenantData, setAvenantData] = useState({ index: 0, contrat_id: 0 });
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const { contrat_id, client_id } = useParams();

  const [isDisabled, setIsDisabled] = useState(false);
  const [dots, setDots] = useState("");
  const maxDots = 2;
  const [tauxCommission, setTauxCommission] = useState(null);
  const [selectedGaranties, setSelectedGaranties] = useState([]);
  const [keepLogo, setKeepLogo] = useState(false);

  const handleCheckboxChange = () => {
    setKeepLogo(!keepLogo);
  };

  const refreshTauxCommission = () => {
    let commissionRate = tauxCommission ? parseFloat(tauxCommission.value) : 0;

    // Gérer le cas des NaN
    commissionRate = isNaN(commissionRate) ? 0 : commissionRate;

    const calculatedCommission = contrat.prime_nette * (commissionRate / 100);
    const calculatedTvaCommission = calculatedCommission * 0.16;

    setContrat({
      ...contrat,
      tva_commission: calculatedTvaCommission.toFixed(2),
      commission: calculatedCommission.toFixed(2),
    });
  };

  const calculatePrimeBrute = () => {
    // Fonction pour parser de manière sûre les valeurs
    const safeParse = (value) =>
      isNaN(parseFloat(value)) ? 0 : parseFloat(value);

    // Débogage : Affichez les valeurs avant le calcul
    console.log("contrat.prime_nette:", contrat.prime_nette);
    console.log("contrat.accessoires:", contrat.accessoires);
    console.log("contrat.frais_arca:", contrat.frais_arca);
    console.log("contrat.frais_sneca:", contrat.frais_sneca);
    console.log("contrat.tva:", contrat.tva);

    // Calculer la prime brute
    const calculatedPrimeBrute =
      safeParse(contrat.prime_nette) +
      safeParse(contrat.accessoires) +
      safeParse(contrat.frais_arca) +
      safeParse(contrat.frais_sneca) +
      safeParse(contrat.tva);

    // Vérifiez que le résultat est un nombre
    console.log("calculatedPrimeBrute:", calculatedPrimeBrute);

    // Vérifiez que calculatedPrimeBrute est un nombre avant d'appeler toFixed
    if (
      typeof calculatedPrimeBrute === "number" &&
      !isNaN(calculatedPrimeBrute)
    ) {
      setContrat({ ...contrat, prime_brute: calculatedPrimeBrute.toFixed(2) });
    } else {
      console.error("Erreur: calculatedPrimeBrute n'est pas un nombre");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < maxDots ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    refreshTauxCommission();
  }, [tauxCommission, contrat.prime_nette]);

  useEffect(() => {
    calculatePrimeBrute();
  }, [
    contrat.prime_nette,
    contrat.accessoires,
    contrat.frais_arca,
    contrat.frais_sneca,
    contrat.tva,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (isLoadingRef.current) return;
      isLoadingRef.current = true;

      try {
        const response = await axios.get(
          `${BASE_API_URL_ENDPOINT}contrat/detail/${contrat_id}/${client_id}`
        );
        if (response.data.state) {
          setSinistre(response.data.data);
          setClient(response.data.data.client_item);
          setContrat(response.data.data.contrat);
          setGaranties(response.data.data.garanties);
          setHistoryData(
            response.data.data.contrat.history != undefined
              ? response.data.data.contrat.history
              : []
          );
          setAvenants(
            response.data.data.contrat.avenants != undefined
              ? response.data.data.contrat.avenants
              : []
          );
          setSelectedGaranties(
            response.data.data.contrat.garanties.map((garantie) => ({
              value: garantie,
              label: garantie,
            }))
          );
          toast.success("Chargement effectué !");
        }
      } catch (error) {
        toast.error("Une erreur est survenue. veuillez réessayer.");
      } finally {
        isLoadingRef.current = false;
      }
    };

    fetchData();
  }, [isUpdated]);

  const deleteContrat = () => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce contrat ? "
    );

    if (confirmation) {
      axios
        .get(`${BASE_API_URL_ENDPOINT}delete/contrat/${contrat_id}`)
        .then((response) => {
          if (response.data.state) {
            toast.success("contrat supprimé avec succès.");
            navigate(`/client/${client_id}`);
          }
        })
        .catch((error) => {
          toast.error("Erreur lors de la suppression du contrat:", error);
        });
    }
  };

  const deleteAvenant = (index) => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet avenant ?"
    );

    if (confirmation) {
      // Appeler l'API pour supprimer l'utilisateur en utilisant axios
      axios
        .get(
          `${BASE_API_URL_ENDPOINT}delete/avenant/${contrat_id}/${client_id}/${index}`
        )
        .then((response) => {
          // Vérifie si la suppression a réussi
          if (response.data.state) {
            toast.success("Avenant supprimée avec succès.");
            setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
          }
        })
        .catch((error) => {
          toast.error("Erreur lors de la suppression de l'avenant :", error);
        });
    }
  };

  const handleFormRenewSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    const formData = new FormData(event.target);
    formData.append("client_id", client.id);
    formData.append("contrat_id", contrat_id);
    formData.append("garanties", JSON.stringify(contrat.garanties));
    axios
      .post(`${BASE_API_URL_ENDPOINT}process/contrat/renew`, formData)
      .then((response) => {
        setIsDisabled(false);
        const { code } = response.data;

        toast.success(
          code === "001"
            ? "Le contrat a été ajouté avec succès !"
            : code === "002"
            ? "Le contrat existe déjà !"
            : "L'opération a réussi !"
        );
        setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
        setTauxCommission(null);
      })
      .catch((error) => {
        toast.error(error.message);

        setTauxCommission(null);
        event.target.reset();
      })
      .finally(() => {
        setIsDisabled(false);
        setTauxCommission(null);
        event.target.reset();
      });
  };

  const handleFormUpdateSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    const formData = new FormData(event.target);
    formData.append("client_id", client.id);
    formData.append("contrat_id", contrat_id);
    formData.append("garanties", JSON.stringify(contrat.garanties));
    try {
      const response = await axios.post(
        `${BASE_API_URL_ENDPOINT}process/contrat/update`,
        formData
      );
      const { code } = response.data;

      toast.success(
        code === "001"
          ? "Le contrat a été ajouté avec succès !"
          : code === "002"
          ? "Le contrat existe déjà !"
          : "L'opération a réussi !"
      );
      setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
      setTauxCommission(null);
    } catch (error) {
      toast.error(error.message);
      setIsDisabled(false);
      setTauxCommission(null);
      event.target.reset();
    } finally {
      setIsDisabled(false);
      setTauxCommission(null);
      event.target.reset();
    }
  };

  const avenantColumns = useMemo(
    () => [
      {
        Header: "Nom",
        accessor: "name",
      },
      {
        Header: "Détails",
        accessor: "detail",
        Cell: ({ row }) =>
          userData.rights.read ? (
            <a
              href={`${BASE_API_DOCUMENT}${row.original.path}`}
              target="_blank"
            >
              <FaEye />
            </a>
          ) : (
            <></>
          ),
      },
      {
        Header: "Supprimer",
        accessor: "delete",
        Cell: ({ row }) =>
          userData.rights.delete ? (
            <Link
              onClick={() => {
                deleteAvenant(row.index);
              }}
            >
              <FaTrash color="#DA542E" />
            </Link>
          ) : (
            <></>
          ),
      },
    ],
    [avenants]
  );

  const columns = useMemo(
    () => [
      {
        Header: "branche",
        accessor: "branche",
      },
      {
        Header: "Numero de police",
        accessor: "numero_police",
      },
      {
        Header: "Effet",
        accessor: "date_debut",
      },
      {
        Header: "Echeance",
        accessor: "date_fin",
      },
      {
        Header: "Prime nette",
        accessor: "prime_nette",
      },
      {
        Header: "Prime brute",
        accessor: "prime_brute",
      },
      {
        Header: "Commission",
        accessor: "commission",
      },
      {
        Header: "Assureur",
        accessor: "nom_assureur",
      },
      {
        Header: "Consulter",
        accessor: "document",
        Cell: ({ row }) => {
          const documents = row.original.document;
          return (
            <div>
              {documents.map((doc, index) => (
                <div key={index}>
                  {userData.rights.read && (
                    <a
                      href={`${BASE_API_DOCUMENT}${doc.path}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        textDecoration: "none",
                      }}
                    >
                      {doc.name}
                    </a>
                  )}
                </div>
              ))}
            </div>
          );
        },
      },
      {
        Header: "Avenants",
        accessor: "avenants",
        Cell: ({ row }) => {
          const handleClick = () => {
            const index = row.index;

            avenantPopUp(index);
          };

          return (
            <Link onClick={handleClick}>
              <FaFile />
            </Link>
          );
        },
      },
    ],
    [historyData]
  );

  const assureurs = [
    { value: "SUNU S.A", label: "SUNU S.A" },
    { value: "RAWSUR S.A", label: "RAWSUR S.A" },
    { value: "RAWSUR LIFE S.A", label: "RAWSUR LIFE S.A" },
    { value: "ACTIVA VIE S.A", label: "ACTIVA VIE S.A" },
    { value: "MAYFAIR S.A", label: "MAYFAIR S.A" },
    { value: "ACTIVA S.A", label: "ACTIVA S.A" },
    { value: "SFA S.A", label: "SFA S.A" },
    { value: "SONAS S.A", label: "SONAS S.A" },
    { value: "AFRISSUR S.A", label: "AFRISSUR S.A" },
  ];

  const defaultAssureur = assureurs.find(
    (assureur) => assureur.value === contrat.nom_assureur
  );

  const tauxCommissionOptions = [
    { value: "5", label: "5%" },
    { value: "10", label: "10%" },
    { value: "12.5", label: "12.5%" },
    { value: "15", label: "15%" },
    { value: "20", label: "20%" },
  ];

  const garantiesOptions =
    garanties &&
    garanties.map((garantie) => ({
      value: garantie.nom,
      label: garantie.nom,
    }));

  const handleChange = (selectedOption) => {
    setContrat({
      ...contrat,
      nom_assureur: selectedOption ? selectedOption.value : null,
    });
  };

  const avenantPopUp = (index) => {
    if (historyData[index].avenants) {
      setAvenantData(historyData[index].avenants);
      setIsAvenantHOpen(true);
    }
  };

  return (
    <Container>
      <Card className="card">
        <div className="card-body">
          <div className="header">
            <BackButton to={`/client/${client_id}`}>
              <FaArrowLeft />
              {client.type != undefined ? (
                <p style={{ marginLeft: "10px" }}>
                  {client.type === 1
                    ? `Contrat (${contrat.branche} - ${contrat.numero_police}) ${client.content_json.company_name} (Entreprise)`
                    : `Contrat (${contrat.branche} - ${contrat.numero_police}) ${client.content_json.client_name} (Particulier)`}
                </p>
              ) : (
                <></>
              )}
            </BackButton>
            <div className="icons">
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setIsActive(0)}
                title="Avenants"
                className="text-info btn-rounded"
              >
                <FaEye />
              </a>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setIsActive(1)}
                title="Avenants"
                className="text-info btn-rounded"
              >
                <FaFile />
              </a>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setIsActive(2)}
                title="Renouvellement"
                className="text-info btn-rounded"
              >
                <FaSync />
              </a>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setIsActive(3)}
                title="Modifier"
                className="text-info btn-rounded"
              >
                <FaEdit />
              </a>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setIsActive(4)}
                title="Historique des contrats"
                className="text-info btn-rounded"
              >
                <FaHistory />
              </a>
              {userData.rights.delete && (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={deleteContrat}
                  title="Supprimer"
                  className="text-danger btn-rounded"
                  id="delete_contrat"
                >
                  <FaTrash />
                </a>
              )}
            </div>
          </div>
          {isActive === 0 ? (
            <FormContainer>
              <FormGroup>
                <Label htmlFor="branche">Branche</Label>
                <Input
                  style={{ backgroundColor: "#2255a4", color: "white" }}
                  type="text"
                  value={contrat.branche}
                  name="branche"
                  id="branche"
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="police">Police</Label>
                <Input
                  style={{ backgroundColor: "#2255a4", color: "white" }}
                  type="text"
                  value={contrat.numero_police}
                  name="numero_police"
                  id="police"
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="type">Type</Label>
                <Input
                  style={{ backgroundColor: "#2255a4", color: "white" }}
                  value={contrat.type_police}
                  type="text"
                  name="type_police"
                  id="type"
                  disabled
                />
              </FormGroup>
              <FormGroup style={{ gridColumn: "span 3" }}>
                <Label htmlFor="garanties">Garanties</Label>
                <SelectStyled>
                  {contrat.garanties !== undefined &&
                    contrat.garanties.map((garantie, index) => (
                      <div key={`garantie-${index}`}>{garantie}</div>
                    ))}
                </SelectStyled>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="effet">Effet</Label>
                <Input
                  value={contrat.date_debut}
                  type="text"
                  name="effet"
                  id="effet"
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="echeance">Échéance</Label>
                <Input
                  value={contrat.date_fin}
                  type="text"
                  name="echeance"
                  id="echeance"
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="prime_nette">Prime nette</Label>
                <Input
                  name="prime_nette"
                  id="prime_nette"
                  value={contrat.prime_nette}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="frais_arca">Frais ARCA</Label>
                <Input
                  type="number"
                  id="frais_arca"
                  name="frais_arca"
                  value={contrat.frais_arca}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="frais_sneca">Frais SNECA</Label>
                <Input
                  type="number"
                  id="frais_sneca"
                  name="frais_sneca"
                  value={contrat.frais_sneca}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="accessoires">Accessoires</Label>
                <Input
                  type="number"
                  id="accessoires"
                  name="accessoires"
                  value={contrat.accessoires}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="tva">TVA</Label>
                <Input
                  type="number"
                  id="tva"
                  name="tva"
                  value={contrat.tva}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="prime_brute">Prime brute</Label>
                <Input
                  type="text"
                  id="prime_brute"
                  name="prime_brute"
                  value={contrat.prime_brute}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="assureur">Assureur</Label>
                <Input
                  type="text"
                  id="assureur"
                  name="assureur"
                  value={contrat.nom_assureur}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="commission">Commission</Label>
                <Input
                  type="text"
                  id="commission"
                  name="commission"
                  value={
                    userData.rights.commission
                      ? isNaN(contrat.commission)
                        ? 0
                        : contrat.commission
                      : "********"
                  }
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="tva_commission">TVA Commission</Label>
                <Input
                  type="text"
                  id="tva_commission"
                  name="tva_commission"
                  value={
                    userData.rights.commission
                      ? isNaN(contrat.tva_commission)
                        ? 0
                        : contrat.tva_commission
                      : "********"
                  }
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="frais_gestion">Frais de gestion</Label>
                <Input
                  type="text"
                  value={
                    userData.rights.commission
                      ? contrat.frais_gestion
                      : "********"
                  }
                  name="frais_gestion"
                  id="frais_gestion"
                  disabled
                />
              </FormGroup>
              <FormGroup style={{ gridColumn: "span 3" }}>
                <Label htmlFor="documents">Documents</Label>
                <SelectStyled>
                  {contrat.document !== undefined &&
                    contrat.document.map((doc, index) => (
                      <a
                        key={`document-${index}`}
                        href={`${BASE_API_DOCUMENT}${doc.path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>{doc.name}</div>
                      </a>
                    ))}
                </SelectStyled>
              </FormGroup>
            </FormContainer>
          ) : isActive === 1 ? (
            <>
              <ButtonContainer>
                <Button
                  onClick={() => {
                    setIsOpenPj(true);
                  }}
                >
                  Ajoutez un avenant
                </Button>
              </ButtonContainer>
              <DataTable
                columns={avenantColumns}
                data={avenants}
                filterValue={filterValue}
                filterFieldIndex={0}
                onFilterChange={setFilterValue}
              />
              <AvenantPopUp
                isOpen={isOpenPj}
                setIsOpen={setIsOpenPj}
                setIsUpdated={setIsUpdated}
                clientId={client_id}
                contratId={contrat_id}
              />
            </>
          ) : isActive === 2 ? (
            <form
              id="form"
              onSubmit={handleFormRenewSubmit}
              encType="multipart/form-data"
            >
              <FormContainer>
                <FormGroup>
                  <Label htmlFor="branche">Branche</Label>
                  <Input
                    type="text"
                    value={contrat.branche}
                    onChange={(e) =>
                      setContrat({ ...contrat, branche: e.target.value })
                    }
                    name="branche"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="police">Police</Label>
                  <Input
                    type="text"
                    value={contrat.numero_police}
                    onChange={(e) =>
                      setContrat({ ...contrat, numero_police: e.target.value })
                    }
                    name="numero_police"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="type">Type</Label>
                  <Input
                    type="text"
                    value={contrat.type_police}
                    onChange={(e) =>
                      setContrat({ ...contrat, type_police: e.target.value })
                    }
                    name="type_police"
                  />
                </FormGroup>
                <FormGroup style={{ gridColumn: "span 3" }}>
                  <Label htmlFor="garanties">Garanties</Label>
                  <SelectStyle
                    name="garanties"
                    options={garantiesOptions}
                    isMulti
                    value={selectedGaranties}
                    onChange={(selectedOptions) => {
                      const values = selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : [];
                      setContrat({ ...contrat, garanties: values });
                      setSelectedGaranties(selectedOptions);
                    }}
                    placeholder="Sélectionnez des garanties"
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="effet">Effet</Label>
                  <StyledDatePicker
                    selected={
                      contrat.date_debut != undefined
                        ? parseDate(contrat.date_debut)
                        : ""
                    }
                    id="date_debut"
                    name="date_debut"
                    onChange={(date) =>
                      setContrat({
                        ...contrat,
                        date_debut: date ? formatDate(date) : "",
                      })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Sélectionner une date"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="echeance">Échéance</Label>
                  <StyledDatePicker
                    selected={
                      contrat.date_fin != undefined
                        ? parseDate(contrat.date_fin)
                        : ""
                    }
                    id="date_fin"
                    name="date_fin"
                    onChange={(date) =>
                      setContrat({
                        ...contrat,
                        date_fin: date ? formatDate(date) : "",
                      })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Sélectionner une date"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="prime_nette">Prime nette</Label>
                  <Input
                    type="number"
                    id="prime_nette"
                    name="prime_nette"
                    value={contrat.prime_nette}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        prime_nette: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="frais_arca">Frais ARCA</Label>
                  <Input
                    type="number"
                    id="frais_arca"
                    name="frais_arca"
                    value={contrat.frais_arca}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        frais_arca: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="frais_sneca">Frais SNECA</Label>
                  <Input
                    type="number"
                    id="frais_sneca"
                    name="frais_sneca"
                    value={contrat.frais_sneca}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        frais_sneca: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="accessoires">Accessoires</Label>
                  <Input
                    type="number"
                    id="accessoires"
                    name="accessoires"
                    value={contrat.accessoires}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        accessoires: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="tva">TVA</Label>
                  <Input
                    type="number"
                    id="tva"
                    name="tva"
                    value={contrat.tva}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        tva: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="prime_brute">Prime brute</Label>
                  <Input
                    type="text"
                    id="prime_brute"
                    name="prime_brute"
                    value={contrat.prime_brute}
                    readOnly
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="assureur">Assureur</Label>
                  <SelectStyle
                    options={assureurs}
                    id="nom_assureur"
                    name="nom_assureur"
                    value={defaultAssureur}
                    onChange={handleChange}
                    placeholder="Sélectionnez un assureur"
                    isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="taux_commission">Taux de commission</Label>
                  <SelectStyle
                    options={tauxCommissionOptions}
                    value={tauxCommission}
                    onChange={setTauxCommission}
                    placeholder="Sélectionnez le taux de commission"
                    isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="commission">Commission</Label>
                  <Input
                    type="text"
                    id="commission"
                    name="commission"
                    value={isNaN(contrat.commission) ? 0 : contrat.commission}
                    readOnly
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="tva_commission">TVA sur commission</Label>
                  <Input
                    type="text"
                    id="tva_commission"
                    name="tva_commission"
                    value={
                      isNaN(contrat.tva_commission) ? 0 : contrat.tva_commission
                    }
                    readOnly
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="frais_gestion">Frais de gestion</Label>
                  <Input
                    type="number"
                    value={contrat.frais_gestion}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        frais_gestion: e.target.value,
                      })
                    }
                    name="frais_gestion"
                    required
                  />
                </FormGroup>
                <FormGroup style={{ gridColumn: "span 3" }}>
                  <Label htmlFor="documents">Documents</Label>
                  <Input type="file" name="documents[]" accept="*" multiple />
                  <div id="fileNames"></div>
                </FormGroup>
                <SubmitButton type="submit" form="form" disabled={isDisabled}>
                  {`${isDisabled ? `.${dots}` : `Renouveler le contrat`}`}
                </SubmitButton>
              </FormContainer>
            </form>
          ) : isActive === 3 ? (
            <form
              id="form"
              onSubmit={handleFormUpdateSubmit}
              encType="multipart/form-data"
            >
              <FormContainer>
                <FormGroup>
                  <Label htmlFor="branche">Branche</Label>
                  <Input
                    type="text"
                    value={contrat.branche}
                    onChange={(e) =>
                      setContrat({ ...contrat, branche: e.target.value })
                    }
                    name="branche"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="police">Police</Label>
                  <Input
                    type="text"
                    value={contrat.numero_police}
                    onChange={(e) =>
                      setContrat({ ...contrat, numero_police: e.target.value })
                    }
                    name="numero_police"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="type">Type</Label>
                  <Input
                    type="text"
                    value={contrat.type_police}
                    onChange={(e) =>
                      setContrat({ ...contrat, type_police: e.target.value })
                    }
                    name="type_police"
                  />
                </FormGroup>
                <FormGroup style={{ gridColumn: "span 3" }}>
                  <Label htmlFor="garanties">Garanties</Label>
                  <SelectStyle
                    name="garanties"
                    options={garantiesOptions}
                    isMulti
                    value={selectedGaranties}
                    onChange={(selectedOptions) => {
                      const values = selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : [];
                      setContrat({ ...contrat, garanties: values });
                      setSelectedGaranties(selectedOptions);
                    }}
                    placeholder="Sélectionnez des garanties"
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="effet">Effet</Label>
                  <StyledDatePicker
                    selected={
                      contrat.date_debut != undefined
                        ? parseDate(contrat.date_debut)
                        : ""
                    }
                    id="date_debut"
                    name="date_debut"
                    onChange={(date) =>
                      setContrat({
                        ...contrat,
                        date_debut: date ? formatDate(date) : "",
                      })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Sélectionner une date"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="echeance">Échéance</Label>
                  <StyledDatePicker
                    selected={
                      contrat.date_fin != undefined
                        ? parseDate(contrat.date_fin)
                        : ""
                    }
                    id="date_fin"
                    name="date_fin"
                    onChange={(date) =>
                      setContrat({
                        ...contrat,
                        date_fin: date ? formatDate(date) : "",
                      })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Sélectionner une date"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="prime_nette">Prime nette</Label>
                  <Input
                    type="number"
                    id="prime_nette"
                    name="prime_nette"
                    value={contrat.prime_nette}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        prime_nette: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="frais_arca">Frais ARCA</Label>
                  <Input
                    type="number"
                    id="frais_arca"
                    name="frais_arca"
                    value={contrat.frais_arca}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        frais_arca: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="frais_sneca">Frais SNECA</Label>
                  <Input
                    type="number"
                    id="frais_sneca"
                    name="frais_sneca"
                    value={contrat.frais_sneca}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        frais_sneca: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="accessoires">Accessoires</Label>
                  <Input
                    type="number"
                    id="accessoires"
                    name="accessoires"
                    value={contrat.accessoires}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        accessoires: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="tva">TVA</Label>
                  <Input
                    type="number"
                    id="tva"
                    name="tva"
                    value={contrat.tva}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        tva: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="prime_brute">Prime brute</Label>
                  <Input
                    type="text"
                    id="prime_brute"
                    name="prime_brute"
                    value={contrat.prime_brute}
                    readOnly
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="assureur">Assureur</Label>
                  <SelectStyle
                    options={assureurs}
                    id="nom_assureur"
                    name="nom_assureur"
                    value={defaultAssureur}
                    onChange={handleChange}
                    placeholder="Sélectionnez un assureur"
                    isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="taux_commission">Taux de commission</Label>
                  <SelectStyle
                    options={tauxCommissionOptions}
                    value={tauxCommission}
                    onChange={setTauxCommission}
                    placeholder="Sélectionnez le taux de commission"
                    isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="commission">Commission</Label>
                  <Input
                    type="text"
                    id="commission"
                    name="commission"
                    value={isNaN(contrat.commission) ? 0 : contrat.commission}
                    readOnly
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="tva_commission">TVA sur commission</Label>
                  <Input
                    type="text"
                    id="tva_commission"
                    name="tva_commission"
                    value={
                      isNaN(contrat.tva_commission) ? 0 : contrat.tva_commission
                    }
                    readOnly
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="frais_gestion">Frais de gestion</Label>
                  <Input
                    type="number"
                    value={contrat.frais_gestion}
                    onChange={(e) =>
                      setContrat({
                        ...contrat,
                        frais_gestion: e.target.value,
                      })
                    }
                    name="frais_gestion"
                    required
                  />
                </FormGroup>
                <FormGroup style={{ gridColumn: "span 3" }}>
                  <Label htmlFor="documents">Documents</Label>
                  <Input
                    type="file"
                    name="documents"
                    accept="*"
                    multiple
                    disabled={keepLogo} // Désactive la sélection de fichier si keepLogo est true
                  />
                  <div id="fileNames"></div>

                  {/* Ajout de la case à cocher "Conserver les anciens documents" avec espace réduit */}
                  <FormGroup
                    check
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Input
                      type="checkbox"
                      name="keep_logo"
                      checked={keepLogo}
                      onChange={handleCheckboxChange} // Gère le changement d'état de la case à cocher
                      style={{
                        margin: "0",
                        padding: "0",
                        width: "16px",
                        height: "16px",
                      }} // Réduit la taille et supprime les marges
                    />
                    <Label
                      check
                      style={{
                        margin: "0",
                        padding: "0",
                        lineHeight: "1",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 10,
                      }}
                    >
                      Conserver les anciens documents
                    </Label>
                  </FormGroup>
                </FormGroup>
                <SubmitButton type="submit" form="form" disabled={isDisabled}>
                  {`${isDisabled ? `.${dots}` : `Mettre à jour le contrat`}`}
                </SubmitButton>
              </FormContainer>
            </form>
          ) : isActive === 4 ? (
            <>
              <DataTable
                columns={columns}
                data={historyData}
                filterValue={filterValue}
                filterFieldIndex={1}
                onFilterChange={setFilterValue}
              />
              <AvenantHPopUp
                isOpen={isAvenantHOpen}
                setIsOpen={setIsAvenantHOpen}
                data={avenantData}
              />
            </>
          ) : null}
        </div>
        <Toaster position="top-center" reverseOrder={false} />
      </Card>
    </Container>
  );
};

export default ContratDetail;
