import React from "react";
import styled from "styled-components";
import { Bar, Line, Pie } from "react-chartjs-2";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";

// Configuration de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);

// Styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  gap: 20px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 24px;
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    color: #007bff;
    background-color: #e6e6e6;
  }
`;

const StatsSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;

const StatCard = styled.div`
  background: #f0f4f8;
  padding: 20px;
  border-radius: 16px; /* Plus arrondi */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 250px;
  text-align: center;
  flex: 1 1 calc(33% - 20px); /* Responsive avec flex-basis */

  @media (max-width: 768px) {
    flex: 1 1 calc(50% - 20px); /* Deux par ligne sur les écrans moyens */
  }

  @media (max-width: 480px) {
    flex: 1 1 100%; /* Un par ligne sur les petits écrans */
  }
`;

const CardTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
`;

const CardValue = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
`;

const ChartsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ChartContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 16px; /* Plus arrondi */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Données pour les graphiques
const barData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Ventes",
      data: [120, 190, 300, 500, 200, 300],
      backgroundColor: "rgba(75, 192, 192, 0.5)",
      borderRadius: 8, // Coins arrondis pour les barres
    },
  ],
};

const lineData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Visiteurs",
      data: [65, 59, 80, 81, 56, 55],
      fill: false,
      borderColor: "rgba(153, 102, 255, 0.5)",
      tension: 0.3, // Adoucir les courbes des lignes
    },
  ],
};

const pieData = {
  labels: ["Red", "Blue", "Yellow"],
  datasets: [
    {
      label: "My First Dataset",
      data: [300, 50, 100],
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)",
        "rgba(54, 162, 235, 0.5)",
        "rgba(255, 206, 86, 0.5)",
      ],
      hoverOffset: 4,
    },
  ],
};

// Composant principal
const Dashboard = () => {
  return (
    <Container>
      {/* Bouton Retour */}
      <Header>
        <BackButton to="/">
          <FaArrowLeft />
        </BackButton>
      </Header>

      {/* Section des Statistiques */}
      <StatsSection>
        <StatCard>
          <CardTitle>Total Utilisateurs</CardTitle>
          <CardValue>1,234</CardValue>
        </StatCard>
        <StatCard>
          <CardTitle>Nouveaux Abonnés</CardTitle>
          <CardValue>567</CardValue>
        </StatCard>
        <StatCard>
          <CardTitle>Ventes du Mois</CardTitle>
          <CardValue>€12,345</CardValue>
        </StatCard>
      </StatsSection>

      {/* Section des Graphiques */}
      <ChartsSection>
        <ChartContainer>
          <Bar
            data={barData}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
        </ChartContainer>
        <ChartContainer>
          <Line
            data={lineData}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
        </ChartContainer>
        <ChartContainer>
          <Pie
            data={pieData}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
        </ChartContainer>
      </ChartsSection>
    </Container>
  );
};

export default Dashboard;
