import React, { useMemo } from "react";
import styled from "styled-components";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { useTable, usePagination, useGlobalFilter } from "react-table";

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /* Si besoin de centrer complètement */
  text-align: center;
`;

const FilterSelect = styled.select`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-width: 200px;
  margin-right: 10px;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-width: 300px;
  width: 100%;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  background: #172169;
  color: #fff;
  border-bottom: 2px solid #ddd;
  text-align: center;
`;

const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  vertical-align: middle; /* Centre verticalement le contenu */
`;

const Tr = styled.tr`
  &:hover {
    background: #f1f1f1;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
`;

const PaginationButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #0056b3;
  }

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;

const DataTable = ({
  columns,
  data,
  filterFieldIndex,
  filterValue,
  onFilterChange,
}) => {
  // Ensure filterFieldIndex is valid and columns array is not empty
  const validFilterFieldIndex =
    filterFieldIndex >= 0 && filterFieldIndex < columns.length;
  const filterAccessor = validFilterFieldIndex
    ? columns[filterFieldIndex].accessor
    : null;

  // Extract unique values from the specified column for the filter dropdown
  const uniqueValues = useMemo(() => {
    if (!validFilterFieldIndex || !filterAccessor) return [];
    const values = new Set(data.map((item) => item[filterAccessor]));
    return Array.from(values);
  }, [data, filterAccessor, validFilterFieldIndex]);

  // Filter data based on the specified column
  const filteredData = useMemo(() => {
    if (!validFilterFieldIndex || !filterAccessor) return data;
    if (filterValue === "all" || filterValue === undefined) return data;
    return data.filter((item) => item[filterAccessor] === filterValue);
  }, [data, filterValue, filterAccessor, validFilterFieldIndex]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <>
      <SearchContainer>
        <FilterSelect
          onChange={(e) => onFilterChange(e.target.value)}
          value={filterValue}
        >
          <option value="all">Tous</option>
          {uniqueValues.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </FilterSelect>
        <Input
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value || undefined)}
          placeholder={"Rechercher..."}
        />
      </SearchContainer>
      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </Th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
      <Pagination>
        <PaginationButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <FaArrowAltCircleLeft />
        </PaginationButton>
        <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
          <FaArrowAltCircleRight />
        </PaginationButton>
      </Pagination>
    </>
  );
};

export default DataTable;
