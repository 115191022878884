import React, { useEffect, useState } from "react";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_API_URL_ENDPOINT } from "../api/Api";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  width: 100%;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 15px;
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ButtonSection = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  padding: 12px 20px;
  background: #172169;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #0056b3;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 10px;
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: 50%;
    max-width: 600px;
    padding: 30px;
    border-radius: 8px;

    @media (max-width: 768px) {
      width: 80%;
    }

    @media (max-width: 480px) {
      width: 95%;
      padding: 20px;
    }
  }
`;

const DocumentPopUp = ({ isOpen, setIsOpen, setIsUpdated, client }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [dots, setDots] = useState("");
  const maxDots = 2;

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < maxDots ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    // Collecte des données du formulaire
    const formData = new FormData(event.target);
    formData.append("client_id", client.id);
    try {
      const response = await axios.post(
        `${BASE_API_URL_ENDPOINT}process/pj/create`,
        formData
      );
      const { code } = response.data;

      toast.success(
        code === "001"
          ? "Le document a été ajouté avec succès !"
          : code === "002"
          ? "Le document existe déjà !"
          : "L'opération a réussi !"
      );
      setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
      setIsOpen(false);
    } catch (error) {
      toast.error(error.message);
      setIsDisabled(false);
      setIsOpen(false);
    } finally {
      setIsDisabled(false);
      setIsOpen(false);
      event.target.reset();
    }
  };

  return (
    <StyledPopup open={isOpen} onClose={() => setIsOpen(false)} modal>
      <Form id="form" onSubmit={handleSubmit} encType="multipart/form-data">
        <FormGroup>
          <Label htmlFor="name">Nom du document</Label>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Nom du document"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="document">Fichier</Label>
          <Input
            type="file"
            id="document"
            name="document"
            accept="*/*"
            required
          />
        </FormGroup>
      </Form>
      <ButtonSection>
        <ButtonContainer>
          <Button type="submit" form="form" disabled={isDisabled}>
            {`${isDisabled ? `.${dots}` : `Enregistrer`}`}
          </Button>
        </ButtonContainer>
      </ButtonSection>
      <Toaster position="top-center" reverseOrder={false} />
    </StyledPopup>
  );
};

export default DocumentPopUp;
