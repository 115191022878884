import React, { useMemo } from "react";
import styled from "styled-components";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { useTable, usePagination, useGlobalFilter } from "react-table";

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /* Si besoin de centrer complètement */
  text-align: center;
`;

const FilterSelect = styled.select`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-width: 200px;
  margin-right: 10px;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-width: 300px;
  width: 100%;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  background: #172169;
  color: #fff;
  border-bottom: 2px solid #ddd;
  text-align: center;
`;

const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  vertical-align: middle; /* Centre verticalement le contenu */
`;

const Tr = styled.tr`
  &:hover {
    background: #f1f1f1;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
`;

const PaginationButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #0056b3;
  }

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;

const DataTable2 = ({
  columns,
  data,
  filterFieldIndex1,
  filterValue1,
  onFilterChange1,
  filterFieldIndex2,
  filterValue2,
  onFilterChange2,
}) => {
  // Ensure filterFieldIndex is valid and columns array is not empty
  const validFilterFieldIndex1 =
    filterFieldIndex1 >= 0 && filterFieldIndex1 < columns.length;
  const filterAccessor1 = validFilterFieldIndex1
    ? columns[filterFieldIndex1].accessor
    : null;

  const validFilterFieldIndex2 =
    filterFieldIndex2 >= 0 && filterFieldIndex2 < columns.length;
  const filterAccessor2 = validFilterFieldIndex2
    ? columns[filterFieldIndex2].accessor
    : null;

  // Extract unique values from the specified columns for both filter dropdowns
  const uniqueValues1 = useMemo(() => {
    if (!validFilterFieldIndex1 || !filterAccessor1) return [];
    const values = new Set(data.map((item) => item[filterAccessor1]));
    return Array.from(values);
  }, [data, filterAccessor1, validFilterFieldIndex1]);

  const uniqueValues2 = useMemo(() => {
    if (!validFilterFieldIndex2 || !filterAccessor2) return [];
    const values = new Set(data.map((item) => item[filterAccessor2]));
    return Array.from(values);
  }, [data, filterAccessor2, validFilterFieldIndex2]);

  // Filter data based on the specified columns
  const filteredData = useMemo(() => {
    let filtered = data;
    if (validFilterFieldIndex1 && filterAccessor1 && filterValue1 !== "all") {
      filtered = filtered.filter(
        (item) => item[filterAccessor1] === filterValue1
      );
    }
    if (validFilterFieldIndex2 && filterAccessor2 && filterValue2 !== "all") {
      filtered = filtered.filter(
        (item) => item[filterAccessor2] === filterValue2
      );
    }
    return filtered;
  }, [
    data,
    filterValue1,
    filterAccessor1,
    validFilterFieldIndex1,
    filterValue2,
    filterAccessor2,
    validFilterFieldIndex2,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <>
      <SearchContainer>
        <FilterSelect
          onChange={(e) => onFilterChange1(e.target.value)}
          value={filterValue1}
        >
          <option value="all">Tous</option>
          {uniqueValues1.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </FilterSelect>
        <FilterSelect
          onChange={(e) => onFilterChange2(e.target.value)}
          value={filterValue2}
        >
          <option value="all">Tous</option>
          {uniqueValues2.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </FilterSelect>
        <Input
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value || undefined)}
          placeholder={"Rechercher..."}
        />
      </SearchContainer>
      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </Th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
      <Pagination>
        <PaginationButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <FaArrowAltCircleLeft />
        </PaginationButton>
        <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
          <FaArrowAltCircleRight />
        </PaginationButton>
      </Pagination>
    </>
  );
};

export default DataTable2;
