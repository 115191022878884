import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { BASE_API_URL_ENDPOINT } from "../api/Api";
import axios from "axios";

// Composants stylisés
const FormCard = styled(Popup)`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 700px;
  width: 100%;
  overflow-y: auto;
  max-height: 80vh;
  overflow-x: hidden;
`;

const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

const FieldWrapper = styled.div`
  flex: 1;
  min-width: 0;
  max-width: 100%;
`;

const Label = styled.label`
  color: #333;
  margin-bottom: 5px;
  display: block;
  font-weight: normal;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #172169;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0f1b4c;
  }
`;

const CloturePopUp = ({
  isOpen,
  setIsOpen,
  sinistreId,
  clientId,
  setIsUpdated,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [dots, setDots] = useState("");
  const [formData, setFormData] = useState({
    statut_sinistre: "",
  });
  const maxDots = 2;

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < maxDots ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const handleSelectChange = (name, selectedOption) => {
    setFormData((prev) => ({ ...prev, [name]: selectedOption.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const submitData = new FormData();
    submitData.append("sinistre_id", sinistreId);
    submitData.append("client_id", clientId);
    submitData.append("statut_sinistre", formData.statut_sinistre);

    axios
      .post(`${BASE_API_URL_ENDPOINT}process/sinistre/cloture`, submitData)
      .then((response) => {
        const { code } = response.data;

        toast.success(
          code === "001"
            ? "Le status a mis à jour avec succès !"
            : "L'opération a réussi !"
        );
        setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
        setIsOpen(false);
        setFormData({
          statut_sinistre: "",
        });
      })
      .catch((error) => {
        toast.error(error.message);
        setIsDisabled(false);
        setIsOpen(false);
        setFormData({
          statut_sinistre: "",
        });
      })
      .finally(() => {
        setIsDisabled(false);
        setIsOpen(false);
        setFormData({
          statut_sinistre: "",
        });
      });
  };

  const statutOptions = [
    { value: "En cours", label: "En cours" },
    { value: "Clôturé indemnisée", label: "Clôturé indemnisé" },
    { value: "Clôturé rejetée", label: "Clôturé rejeté" },
  ];

  return (
    <FormCard open={isOpen} onClose={() => setIsOpen(false)} modal>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FieldWrapper>
            <Label htmlFor="statut_sinistre">Statut</Label>
            <Select
              options={statutOptions}
              onChange={(option) =>
                handleSelectChange("statut_sinistre", option)
              }
              value={statutOptions.find(
                (option) => option.value === formData.statut_sinistre
              )}
            />
          </FieldWrapper>
        </FormGroup>
        <Button type="submit" disabled={isDisabled}>{`${
          isDisabled ? `.${dots}` : `Modifier le statut`
        }`}</Button>
      </form>
      <Toaster position="top-center" reverseOrder={false} />
    </FormCard>
  );
};

export default CloturePopUp;
