import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_API_URL_ENDPOINT } from "../api/Api";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useSelector } from "react-redux";
import { FaPaperPlane, FaTimes } from "react-icons/fa";

const StyledPopup = styled(Popup)`
  &-content {
    width: 50%;
    max-width: 600px;
    padding: 30px;
    border-radius: 8px;

    @media (max-width: 768px) {
      width: 80%;
    }

    @media (max-width: 480px) {
      width: 95%;
      padding: 20px;
    }
  }
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  background-color: #f5f5f5;
  width: 100%;
`;

const ChatList = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  max-height: calc(70vh - 120px);
`;

const ChatItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const ChatImg = styled.div`
  flex-shrink: 0;
  margin-right: 15px;
  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
`;

const ChatContent = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  max-width: 90%;
  word-break: break-word;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
`;

const Timestamp = styled.span`
  color: #999;
`;

const MessageText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  margin-left: 15px;
  &:hover {
    text-decoration: underline;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column; /* Empile les éléments verticalement */
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  background-color: #ffffff;
`;

const Select = styled.select`
  margin-bottom: 10px; /* Espacement sous le select */
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 100%; /* Prend toute la largeur disponible */
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
`;

const Textarea = styled.textarea`
  margin-bottom: 10px; /* Espacement sous la zone de saisie */
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 100%; /* Prend toute la largeur disponible */
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligne le bouton à droite */
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const AlertPopUp = ({ isOpen, setIsOpen, commentData }) => {
  const isLoadingRef = useRef(false);
  const userData = useSelector((state) => state.user.userData);
  const [isUpdated, setIsUpdated] = useState(0);
  const [commentaires, setCommentaires] = useState([]);
  const [statut, setStatut] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [commentValues, setCommentValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (isLoadingRef.current) return;
      isLoadingRef.current = true;

      const source = axios.CancelToken.source();

      try {
        if (!isOpen) return;

        const response = await axios.get(
          `${BASE_API_URL_ENDPOINT}alertes/comment/${commentData.id}/${commentData.client_id}`,
          {
            cancelToken: source.token,
          }
        );

        if (response.data.state) {
          setCommentaires(response.data.data.commentaires);
          setStatut(response.data.data.statut);
          toast.success("Chargement effectué !");
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request cancelled", error.message);
        } else {
          toast.error("Une erreur est survenue. veuillez réessayer.");
        }
      } finally {
        isLoadingRef.current = false;
      }

      return () => {
        source.cancel("Component unmounted");
      };
    };

    fetchData();

    return () => {
      setCommentaires([]);
      setStatut("");
    };
  }, [isOpen, commentData, isUpdated]);

  const handleEdit = async (index) => {
    if (editingIndex === index) {
      const updatedComment = commentValues[index];
      const success = await updateComment(
        commentData.id,
        index,
        updatedComment
      );
      if (success) {
        setIsUpdated((prev) => prev + 1);
      }
    }
    setEditingIndex(editingIndex === index ? null : index);
  };

  const handleDelete = async (index) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ? Cette opération est irréversible."
      )
    ) {
      const success = await deleteComment(commentData.id, index);
      if (success) {
        setIsUpdated((prev) => prev + 1);
      }
    }
  };

  const handleChange = (index, value) => {
    setCommentValues((prev) => ({ ...prev, [index]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("client_id", commentData.client_id);
    formData.append("contrat_id", commentData.id);
    formData.append("user", JSON.stringify(userData));
    try {
      await axios.post(
        `${BASE_API_URL_ENDPOINT}process/alerte/comment/create`,
        formData
      );
      setIsUpdated((prev) => prev + 1);
    } catch (error) {
      setIsUpdated((prev) => prev + 1);
    } finally {
      event.target.reset();
    }
  };

  const deleteComment = async (contratId, index) => {
    const formData = new FormData();
    formData.append("id", contratId);
    formData.append("index", index);
    try {
      const response = await axios.post(
        `${BASE_API_URL_ENDPOINT}delete/alerte/comment`,
        formData
      );
      if (response.data.state) {
        setIsUpdated((prev) => prev + 1);
      }
    } catch (error) {
      setIsUpdated((prev) => prev + 1);
    }
    setIsUpdated((prev) => prev + 1);
  };

  const updateComment = async (contratId, index, value) => {
    const formData = new FormData();
    formData.append("id", contratId);
    formData.append("index", index);
    formData.append("value", value);
    formData.append("user", JSON.stringify(userData));

    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    try {
      const response = await axios.post(
        `${BASE_API_URL_ENDPOINT}update/alerte/comment`,
        formData
      );
      if (response.data.state) {
        setIsUpdated((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Error updating comment:", error);
      setIsUpdated((prev) => prev + 1);
    }
    setIsUpdated((prev) => prev + 1);
  };

  return (
    <StyledPopup open={isOpen} onClose={() => setIsOpen(false)} modal>
      <ChatContainer>
        <ChatList>
          {commentaires.map((commentaire, index) => {
            const date = new Date(
              commentaire.updated
                ? commentaire.updated_value.date
                : commentaire.date
            );
            const options = {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedDate = date.toLocaleDateString("fr-FR", options);

            return (
              <ChatItem key={index}>
                <ChatImg>
                  <img src="../../assets/images/users/1.jpg" alt="user" />
                </ChatImg>
                <ChatContent>
                  <MessageHeader>
                    <span>
                      {commentaire.user.data.complete_name} (
                      {commentaire.statut})
                    </span>
                    <Timestamp>
                      {commentaire.updated
                        ? `Modifié ${formattedDate} par ${commentaire.updated_value.user.data.complete_name}`
                        : formattedDate}
                    </Timestamp>
                  </MessageHeader>
                  {editingIndex === index ? (
                    <Textarea
                      value={commentValues[index] || commentaire.commentaire}
                      onChange={(e) => handleChange(index, e.target.value)}
                    />
                  ) : (
                    <MessageText>{commentaire.commentaire}</MessageText>
                  )}
                  <Actions>
                    <ActionButton
                      onClick={() => handleEdit(index)}
                      title={
                        editingIndex === index ? "Enregistrer" : "Modifier"
                      }
                    >
                      {editingIndex === index ? "Enregistrer" : "Modifier"}
                    </ActionButton>
                    {userData.rights.delete && (
                      <ActionButton
                        onClick={() => handleDelete(index)}
                        title="Supprimer"
                      >
                        <FaTimes color="red" />
                      </ActionButton>
                    )}
                  </Actions>
                </ChatContent>
              </ChatItem>
            );
          })}
        </ChatList>

        {userData.rights.write && (
          <Form onSubmit={handleSubmit}>
            <Select
              name="statut"
              required
              value={statut}
              onChange={(e) => setStatut(e.target.value)}
            >
              <option value="">Veuillez sélectionner le statut</option>
              <option value="Non traité">Non traité</option>
              <option value="Demande de projet de renouvellement">
                Demande de projet de renouvellement
              </option>
              <option value="Projet de renouvellement et facture transmis au client">
                Projet de renouvellement et facture transmis au client
              </option>
              <option value="Attente">Attente</option>
            </Select>
            <Textarea
              name="commentaire"
              placeholder="Votre commentaire..."
              required
            />
            <SubmitButtonWrapper>
              <SubmitButton type="submit">
                <FaPaperPlane />
              </SubmitButton>
            </SubmitButtonWrapper>
          </Form>
        )}
      </ChatContainer>
      <Toaster position="top-center" reverseOrder={false} />
    </StyledPopup>
  );
};

export default AlertPopUp;
