import { parse, isValid } from "date-fns";

export function formatDate(dateString) {
  // Vérifie si la date est au format JJ/MM/AAAA (ex: 25/12/2023)
  const regexDateFrancaise = /^\d{2}\/\d{2}\/\d{4}$/;

  if (regexDateFrancaise.test(dateString)) {
    // Si c'est le cas, on doit inverser JJ/MM/AAAA en AAAA-MM-JJ pour que le constructeur de Date le comprenne
    const [jour, mois, annee] = dateString.split("/");
    const dateFormatISO = `${annee}-${mois}-${jour}`;

    const dateObj = new Date(dateFormatISO);

    // Vérifie si la date est valide
    if (isNaN(dateObj.getTime())) {
      console.error("Date invalide : ", dateString);
      return "Date invalide";
    }

    return dateObj.toLocaleDateString("fr-FR");
  }

  // Sinon, on assume que la date est dans un format compatible avec le constructeur de Date
  const dateObj = new Date(dateString);

  // Vérifie si la date est valide
  if (isNaN(dateObj.getTime())) {
    console.error("Date invalide : ", dateString);
    return "Date invalide";
  }

  return dateObj.toLocaleDateString("fr-FR");
}

export const parseDate = (dateString) => {
  const date = parse(dateString, "dd/MM/yyyy", new Date());
  return isValid(date) ? date : null;
};
