import { LOCAL_STORAGE } from "../../../storage/session";
import { CHECK_USER, LOGIN_USER, LOGOUT_USER } from "./types";

export const initialState = {
  userData: null,
  isUserConnected: false,
};

const _setLocalUser = async (value) => {
  try {
    await localStorage
      .setItem(LOCAL_STORAGE.user, JSON.stringify(value))
      .then((res) => {
        return true;
      });
  } catch (error) { 
    console.error(error);
  }
};

const _removeLocalUser = async () => {
  try {
    await localStorage.removeItem(LOCAL_STORAGE.user).then((res) => {
      return true;
    });
  } catch (error) {
    console.error(error);
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      if (_setLocalUser(action.payload)) {
        return {
          ...state,
          userData: action.payload,
          isUserConnected: true,
        };
      }
      break;
    case LOGOUT_USER:
      if (_removeLocalUser()) {
        return {
          ...state,
          userData: null,
          isUserConnected: false,
        };
      }
      break;
    case CHECK_USER:
      if (action.payload !== null) {
        return {
          ...state,
          userData: action.payload,
          isUserConnected: true,
        };
      } else {
        return {
          ...state,
          userData: null,
          isUserConnected: false,
        };
      }
    default:
      return state;
  }
};

export default userReducer;
