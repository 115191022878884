import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  justify-content: ${({ center, start }) =>
    center ? "center" : start ? "flex-start" : "flex-start"};
  margin-top: ${({ marginTop }) => (marginTop ? "-20px" : "0")};
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px; /* Ajoute un espace entre les colonnes */
  width: 100%;
`;

const GridItem = styled.div`
  grid-column: span ${({ span }) => span};
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const TextDisplay = styled.p`
  background-color: #f8f9fa;
  font-size: 1rem;
  color: #495057;
  margin-top: 0.5rem;
`;

const Img = styled.img`
  width: 15%;
  height: auto;
  display: block;
`;

const ClientComponent = ({ clientItem }) => {
  const {
    type,
    content_json: {
      company_name = "",
      company_adress = "",
      company_phone_number = "",
      company_mail = "",
      rccm = "",
      impot = "",
      contact_name = "",
      contact_position = "",
      contact_phone_number = "",
      contact_mail = "",
      client_name = "",
      client_adress = "",
      client_phone_number_1 = "",
      client_phone_number_2 = "",
      client_mail = "",
    } = {},
  } = clientItem || {}; // Chaînage optionnel pour éviter les erreurs si clientItem est undefined

  return (
    <Container>
      {type === 1 ? (
        <>
          <Row center>
            <Img src="../../assets/images/users/1.jpg" alt="User" />
          </Row>
          <Grid>
            <GridItem span={3}>
              <Label htmlFor="Nom">Nom</Label>
            </GridItem>
            <GridItem span={4}>
              <Label htmlFor="Adresse">Adresse</Label>
            </GridItem>
            <GridItem span={2}>
              <Label htmlFor="Téléphone">Téléphone</Label>
            </GridItem>
            <GridItem span={3}>
              <Label htmlFor="Type police">Type police</Label>
            </GridItem>
            <GridItem span={3}>
              <TextDisplay>{company_name}</TextDisplay>
            </GridItem>
            <GridItem span={4}>
              <TextDisplay>{company_adress}</TextDisplay>
            </GridItem>
            <GridItem span={2}>
              <TextDisplay>{company_phone_number}</TextDisplay>
            </GridItem>
            <GridItem span={3}>
              <TextDisplay>{company_mail}</TextDisplay>
            </GridItem>
            <GridItem span={2}>
              <Label htmlFor="rccm">RCCM</Label>
            </GridItem>
            <GridItem span={2}>
              <Label htmlFor="impot">Numéro d'impot</Label>
            </GridItem>
            <GridItem span={4}>
              <Label htmlFor="Personne de contact">Personne de contact</Label>
            </GridItem>
            <GridItem span={4}>
              <Label htmlFor="Fonction">Fonction</Label>
            </GridItem>
            <GridItem span={2}>
              <TextDisplay>{rccm}</TextDisplay>
            </GridItem>
            <GridItem span={2}>
              <TextDisplay>{impot}</TextDisplay>
            </GridItem>
            <GridItem span={4}>
              <TextDisplay>{contact_name}</TextDisplay>
            </GridItem>
            <GridItem span={4}>
              <TextDisplay>{contact_position}</TextDisplay>
            </GridItem>
            <GridItem span={6}>
              <Label htmlFor="Téléphone">Téléphone</Label>
            </GridItem>
            <GridItem span={6}>
              <Label htmlFor="Adresse mail">Adresse mail</Label>
            </GridItem>
            <GridItem span={6}>
              <TextDisplay>{contact_phone_number}</TextDisplay>
            </GridItem>
            <GridItem span={6}>
              <TextDisplay>{contact_mail}</TextDisplay>
            </GridItem>
          </Grid>
        </>
      ) : (
        <>
          <Grid>
            <GridItem span={5}>
              <Label htmlFor="nom">Nom</Label>
            </GridItem>
            <GridItem span={7}>
              <Label htmlFor="adresse">Adresse</Label>
            </GridItem>
            <GridItem span={5}>
              <TextDisplay>{client_name}</TextDisplay>
            </GridItem>
            <GridItem span={7}>
              <TextDisplay>{client_adress}</TextDisplay>
            </GridItem>
            <GridItem span={4}>
              <Label htmlFor="phone">Téléphone</Label>
            </GridItem>
            <GridItem span={3}>
              <Label htmlFor="phone 2">Téléphone 2</Label>
            </GridItem>
            <GridItem span={5}>
              <Label htmlFor="adresse">Adresse mail</Label>
            </GridItem>
            <GridItem span={4}>
              <TextDisplay>{client_phone_number_1}</TextDisplay>
            </GridItem>
            <GridItem span={3}>
              <TextDisplay>{client_phone_number_2}</TextDisplay>
            </GridItem>
            <GridItem span={5}>
              <TextDisplay>{client_mail}</TextDisplay>
            </GridItem>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default ClientComponent;
