import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaSearch, FaPlus, FaBell, FaParking } from "react-icons/fa";
import { RiAlertFill } from "react-icons/ri";
import { GiCommercialAirplane } from "react-icons/gi";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BASE_API_URL_ENDPOINT } from "../api/Api";
import { useSelector } from "react-redux";
import Cartes from "../components/Cartes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
`;

const Ligne = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 100%;
`;

const Carte = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || "white"};
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 120px;
  height: 120px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  text-decoration: none;

  &:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const Badge = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
`;

const Home = () => {
  const [alertesCount, setAlertesCount] = useState();
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    const fetchAlertes = () => {
      axios
        .get(`${BASE_API_URL_ENDPOINT}process/get/alertes`)
        .then((response) => {
          if (response.data.state) {
            setAlertesCount(response.data.data.number);
          } else {
            setAlertesCount();
            toast.error(
              response.data.message ||
                "Erreur inattendue lors du chargement des alertes"
            );
          }
        })
        .catch((err) => {
          setAlertesCount();
          toast.error(
            err.message || "Erreur de requête lors du chargement des alertes"
          );
        });
    };

    fetchAlertes();
  }, []);

  const ligne1 =
    userData.type !== 1
      ? [
          { icon: <FaSearch size={40} />, text: "Clients", path: "clients" },
          {
            icon: <FaSearch size={40} />,
            text: "Prospects",
            path: "prospects",
          },
          {
            icon: <FaSearch size={40} />,
            text: "Documents",
            path: "documents",
          },
          {
            icon: <FaSearch size={40} />,
            text: "Garanties",
            path: "garanties",
          },
        ]
      : [
          {
            icon: <FaSearch size={40} />,
            text: "Utilisateurs",
            path: "users",
          },
          { icon: <FaSearch size={40} />, text: "Clients", path: "clients" },
          {
            icon: <FaSearch size={40} />,
            text: "Prospects",
            path: "prospects",
          },
          {
            icon: <FaSearch size={40} />,
            text: "Documents",
            path: "documents",
          },
          {
            icon: <FaSearch size={40} />,
            text: "Garanties",
            path: "garanties",
          },
        ];
  const ligne2 =
    userData.type !== 1
      ? [
          { icon: <FaPlus size={40} />, text: "Client", path: "client" },
          { icon: <FaPlus size={40} />, text: "Prospect", path: "prospect" },
          { icon: <FaPlus size={40} />, text: "Document", path: "document" },
          { icon: <FaPlus size={40} />, text: "Garantie", path: "garantie" },
        ]
      : [
          {
            icon: <FaPlus size={40} />,
            text: "Utilisateur",
            path: "user",
          },
          { icon: <FaPlus size={40} />, text: "Client", path: "client" },
          { icon: <FaPlus size={40} />, text: "Prospect", path: "prospect" },
          { icon: <FaPlus size={40} />, text: "Document", path: "document" },
          { icon: <FaPlus size={40} />, text: "Garantie", path: "garantie" },
        ];
  const ligne3 = [
    {
      icon: <RiAlertFill size={40} />,
      text: "Alertes",
      number: alertesCount,
      path: "alertes",
    },
    { icon: <FaBell size={40} />, text: "Communication", path: "notification" },
    {
      icon: <GiCommercialAirplane size={40} />,
      text: "Assistance voyage",
      path: "assvoyage",
    },
    {
      icon: <FaParking size={40} />,
      text: "Police au voyage",
      path: "polvoyage",
    },
  ];
  return (
    <Container>
      <Ligne>
        <Cartes items={ligne1} bgColor="#172169" />
      </Ligne>
      <Ligne>
        <Cartes items={ligne2} bgColor="#42a23d" />
      </Ligne>
      <Ligne>
        <Cartes items={ligne3} bgColor="#DA542E" />
      </Ligne>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default Home;
