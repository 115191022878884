import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaSearch, FaPlus, FaBell, FaParking } from "react-icons/fa";
import { RiAlertFill } from "react-icons/ri";
import { GiCommercialAirplane } from "react-icons/gi";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BASE_API_URL_ENDPOINT } from "../api/Api";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
`;

const Ligne = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 100%;
`;

const Carte = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || "white"};
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 120px;
  height: 120px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  text-decoration: none;

  &:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const Badge = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
`;

const Cartes = ({ items, bgColor }) => {
  return items.map((item, index) => (
    <Carte key={index} bgColor={bgColor} to={item.path}>
      {item.number && <Badge>{item.number}</Badge>} {item.icon}
      <p>{item.text}</p>
    </Carte>
  ));
};

export default Cartes;