import { isUserExist } from "../../../storage/session";
import { CHECK_USER, LOGIN_USER, LOGOUT_USER } from "./types";

export const registerUser = (userData) => {
  return {
    type: LOGIN_USER,
    payload: userData,
  };
};

export const logoutUser = () => { 
  return {
    type: LOGOUT_USER,
    payload: [],
  };
};

export const checkUser = async () => {
  return {
    type: CHECK_USER,
    payload: isUserExist(),
  };
};
