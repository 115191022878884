import { createStore, combineReducers, applyMiddleware } from "redux";
import promise from "redux-promise";
import logger from "redux-logger";

// reducers
import userReducer from "./features/user/reducers";

const middleWare = applyMiddleware(promise, logger);

const store = createStore(
  combineReducers({
    user: userReducer,  
  }),
  middleWare
);

export default store;
