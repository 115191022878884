export const LOCAL_STORAGE = {
  user: "user",
};

export const isUserExist = async () => {
  try {
    const userStr = localStorage.getItem(LOCAL_STORAGE.user);
    return userStr != null ? JSON.parse(userStr) : null;
  } catch (e) {
    console.warn(e);
  }
};
