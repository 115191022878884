import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { FaArrowLeft, FaTrash, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BASE_API_DOCUMENT, BASE_API_URL_ENDPOINT } from "../api/Api";
import DataTable from "../components/DataTable";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DocumentPopUp from "../components/DocumentPopUp";
import CommentPopUp from "../components/CommentPopUp";
import AddSinistrePopUp from "../components/AddSinistrePopUp";
import DataTable2 from "../components/DataTable2";
import AddContratPopUp from "../components/AddContratPopUp";
import ClientCard from "../components/ClientCard";

const Container = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 24px;
  &:hover {
    color: #007bff;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  display: flex;
`;

const TabItem = styled.p`
  margin-left: 10px;
  padding: 5px;
  color: ${({ isActive }) => (isActive ? "#0056b3" : "#007BFF")};
  cursor: pointer;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? "#0056b3" : "transparent")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};

  &:hover {
    border-bottom-color: #007bff;
  }

  &:active {
    color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  background-color: #172169;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const ClientDetail = () => {
  const [filterValue, setFilterValue] = useState("all");
  const [filterValue2, setFilterValue2] = useState("all");
  const [isActive, setIsActive] = useState(2);
  const [isUpdated, setIsUpdated] = useState(0);
  const [isOpenAddDoc, setIsOpenAddDoc] = useState(false);
  const [isOpenCommentDoc, setIsOpenCommentDoc] = useState(false);
  const [isOpenSinistre, setIsOpenSinistre] = useState(false);
  const [isOpenContrat, setIsOpenContrat] = useState(false);
  const isLoadingRef = useRef(false);
  const [client, setClient] = useState({});
  const [contrats, setContrats] = useState([]);
  const [sinistres, setSinistres] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [garanties, setGaranties] = useState([]);
  const [commentaires, setCommentaires] = useState([]);
  const userData = useSelector((state) => state.user.userData);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (isLoadingRef.current) return;

      isLoadingRef.current = true;

      try {
        const response = await axios.get(
          `${BASE_API_URL_ENDPOINT}client/${id}`
        );

        setClient(response.data.data.client_item);
        setContrats(response.data.data.contrats);
        setSinistres(response.data.data.sinistres);
        setDocuments(response.data.data.piece_jointes);
        setCommentaires(response.data.data.commentaires);
        setGaranties(response.data.data.garanties);
        toast.success("Chargement effectué !");
      } catch (error) {
        toast.error("Une erreur est survenue. veuillez réessayer.");
      } finally {
        isLoadingRef.current = false;
      }
    };

    fetchData();
  }, [isUpdated]);

  const deleteDocument = (id) => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce client ? "
    );

    if (confirmation) {
      axios
        .get(`${BASE_API_URL_ENDPOINT}delete/pjj/${client.id}/${id}`)
        .then((response) => {
          if (response.data.state) {
            toast.success("Document supprimé avec succès.");
            setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
          }
        })
        .catch((error) => {
          toast.error("Erreur lors de la suppression du document:", error);
        });
    }
  };

  const contratList = useMemo(
    () => [
      {
        Header: "Branche",
        accessor: "content_json.branche",
      },
      {
        Header: "Numéro de police",
        accessor: "content_json.numero_police",
      },
      {
        Header: "Détail",
        accessor: "détail",
        Cell: ({ row }) =>
          userData.rights.delete ? (
            <Link
              to={`/contrat/detail/${row.original.id}/${row.original.client_id}`}
            >
              <FaEye color="#172169" />
            </Link>
          ) : (
            <></>
          ),
      },
    ],
    [contrats]
  );

  const sinistreList = useMemo(
    () => [
      {
        Header: "Type de police",
        accessor: "content_json.type_police",
      },
      {
        Header: "Numéro de police",
        accessor: "content_json.numero_police",
      },
      {
        Header: "Numéro du sinistre",
        accessor: "content_json.numero_sinistre",
      },
      {
        Header: "Statut",
        accessor: "content_json.statut_sinistre",
      },
      {
        Header: "Détail",
        accessor: "détail",
        Cell: ({ row }) =>
          userData.rights.delete ? (
            <Link
              to={`/sinistre/detail/${row.original.id}/${row.original.client_id}`}
            >
              <FaEye color="#172169" />
            </Link>
          ) : (
            <></>
          ),
      },
    ],
    [sinistres]
  );

  const documentList = useMemo(
    () => [
      {
        Header: "Nom",
        accessor: "name",
      },
      {
        Header: "Détails",
        accessor: "path",
        Cell: ({ row }) => (
          <a href={`${BASE_API_DOCUMENT}${row.original.path}`} target="_blank">
            <FaEye />
          </a>
        ),
      },
      {
        Header: "Supprimer",
        accessor: "delete",
        Cell: ({ row }) =>
          userData.rights.delete ? (
            <Link onClick={() => deleteDocument(row.index)}>
              <FaTrash color="#DA542E" />
            </Link>
          ) : (
            <></>
          ),
      },
    ],
    [documents]
  );

  return (
    <Container>
      <Header>
        <SearchContainer>
          <BackButton to="/clients">
            <FaArrowLeft />
            {client.type != undefined ? (
              <p style={{ marginLeft: "10px" }}>
                {client.type === 1
                  ? `${client.content_json.company_name} (Entreprise)`
                  : `${client.content_json.client_name} (Particulier)`}
              </p>
            ) : (
              <></>
            )}
          </BackButton>
          <Tab>
            <TabItem isActive={isActive === 1} onClick={() => setIsActive(1)}>
              Client
            </TabItem>
            <TabItem isActive={isActive === 2} onClick={() => setIsActive(2)}>
              Contrats d'assurances
            </TabItem>
            <TabItem isActive={isActive === 3} onClick={() => setIsActive(3)}>
              Sinistres
            </TabItem>
            <TabItem isActive={isActive === 4} onClick={() => setIsActive(4)}>
              Documents
            </TabItem>
          </Tab>
        </SearchContainer>
      </Header>
      {isActive === 2 ? (
        <ButtonContainer>
          <Button
            onClick={() => {
              setIsOpenContrat(true);
            }}
          >
            Ajoutez un contrat
          </Button>
        </ButtonContainer>
      ) : isActive === 3 ? (
        <ButtonContainer>
          <Button
            onClick={() => {
              setIsOpenSinistre(true);
            }}
          >
            Ajoutez un sinistre
          </Button>
        </ButtonContainer>
      ) : isActive === 4 ? (
        <ButtonContainer>
          <Button
            onClick={() => {
              setIsOpenCommentDoc(true);
            }}
          >
            Voir les commentaires
          </Button>
          <Button
            onClick={() => {
              setIsOpenAddDoc(true);
            }}
          >
            Ajoutez un document
          </Button>
        </ButtonContainer>
      ) : (
        []
      )}
      {isActive === 3 ? (
        <DataTable2
          columns={sinistreList}
          data={sinistres}
          filterValue1={filterValue}
          filterValue2={filterValue2}
          onFilterChange1={setFilterValue}
          onFilterChange2={setFilterValue2}
          filterFieldIndex1={0}
          filterFieldIndex2={3}
        />
      ) : isActive === 2 ? (
        <DataTable
          columns={contratList}
          data={contrats}
          filterValue={filterValue}
          filterFieldIndex={0}
          onFilterChange={setFilterValue}
        />
      ) : isActive === 4 ? (
        <DataTable
          columns={documentList}
          data={documents}
          filterValue={filterValue}
          filterFieldIndex={0}
          onFilterChange={setFilterValue}
        />
      ) : (
        <ClientCard clientItem={client} />
      )}
      <DocumentPopUp
        isOpen={isOpenAddDoc}
        setIsOpen={setIsOpenAddDoc}
        setIsUpdated={setIsUpdated}
        client={client}
      />
      <CommentPopUp
        commentaires={commentaires}
        isOpen={isOpenCommentDoc}
        setIsOpen={setIsOpenCommentDoc}
        setIsUpdated={setIsUpdated}
        client={client}
      />
      <AddSinistrePopUp
        isOpen={isOpenSinistre}
        setIsOpen={setIsOpenSinistre}
        setIsUpdated={setIsUpdated}
        client={client}
      />
      <AddContratPopUp
        isOpen={isOpenContrat}
        setIsOpen={setIsOpenContrat}
        setIsUpdated={setIsUpdated}
        client={client}
        garanties={garanties}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default ClientDetail;
