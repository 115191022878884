import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { BASE_API_URL_ENDPOINT } from "../api/Api";
import axios from "axios";

// Composants stylisés
const FormCard = styled(Popup)`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 700px;
  width: 100%;
  overflow-y: auto;
  max-height: 80vh;
  overflow-x: hidden;
`;

const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

const FieldWrapper = styled.div`
  flex: 1;
  min-width: 0;
  max-width: 100%;
`;

const Label = styled.label`
  color: #333;
  margin-bottom: 5px;
  display: block;
  font-weight: normal;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #172169;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0f1b4c;
  }
`;

const UpdateSinistrePopUp = ({
  isOpen,
  setIsOpen,
  sinistreId,
  clientId,
  sinistre,
  setIsUpdated,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [dots, setDots] = useState("");
  const [formData, setFormData] = useState({
    type_police: "",
    numero_police: "",
    numero_sinistre: "",
    date_sinistre: null,
    date_declaration: null,
    statut_sinistre: "",
    nom_assureur: "",
  });
  const maxDots = 2;

  useEffect(() => {
    // Vérification si les dates existent avant de les convertir en objets Date
    const dateSinistre = sinistre.date_sinistre
      ? new Date(sinistre.date_sinistre)
      : null;
    const dateDeclaration = sinistre.date_declaration
      ? new Date(sinistre.date_declaration)
      : null;

    setFormData({
      type_police: sinistre.type_police || "",
      numero_police: sinistre.numero_police || "",
      numero_sinistre: sinistre.numero_sinistre || "",
      date_sinistre:
        dateSinistre && !isNaN(dateSinistre.getTime()) ? dateSinistre : null,
      date_declaration:
        dateDeclaration && !isNaN(dateDeclaration.getTime())
          ? dateDeclaration
          : null,
      statut_sinistre: sinistre.statut_sinistre || "",
      nom_assureur: sinistre.nom_assureur || "",
    });
  }, [sinistre]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < maxDots ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    setFormData((prev) => ({ ...prev, [name]: date }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setFormData((prev) => ({ ...prev, [name]: selectedOption.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);

    const submitData = new FormData();
    submitData.append("client_id", clientId);
    submitData.append("sinistre_id", sinistreId);
    submitData.append("type_police", formData.type_police);
    submitData.append("numero_police", formData.numero_police);
    submitData.append("numero_sinistre", formData.numero_sinistre);
    submitData.append("statut_sinistre", formData.statut_sinistre);
    submitData.append("nom_assureur", formData.nom_assureur);
    submitData.append("date_declaration", formData.date_declaration);
    submitData.append("date_sinistre", formData.date_sinistre);

    axios
      .post(`${BASE_API_URL_ENDPOINT}process/sinistre/update`, submitData)
      .then((response) => {
        const { code } = response.data;

        toast.success(
          code === "001"
            ? "Le sinistre a été mis à jour avec succès !"
            : "L'opération a réussi !"
        );
        setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsDisabled(false);
        setIsOpen(false);
      })
      .finally(() => {
        setIsDisabled(false);
        setIsOpen(false);
      });
  };

  const statutOptions = [
    { value: "En cours", label: "En cours" },
    { value: "Clôturé indemnisée", label: "Clôturé indemnisé" },
    { value: "Clôturé rejetée", label: "Clôturé rejeté" },
  ];

  const assureurOptions = [
    { value: "SUNU S.A", label: "SUNU S.A" },
    { value: "RAWSUR S.A", label: "RAWSUR S.A" },
    { value: "RAWSUR LIFE S.A", label: "RAWSUR LIFE S.A" },
    { value: "ACTIVA VIE S.A", label: "ACTIVA VIE S.A" },
    { value: "MAYFAIR S.A", label: "MAYFAIR S.A" },
    { value: "ACTIVA S.A", label: "ACTIVA S.A" },
    { value: "SFA S.A", label: "SFA S.A" },
    { value: "SONAS S.A", label: "SONAS S.A" },
    { value: "AFRISSUR S.A", label: "AFRISSUR S.A" },
  ];

  return (
    <FormCard open={isOpen} onClose={() => setIsOpen(false)} modal>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FieldWrapper>
            <Label htmlFor="type_police">Type</Label>
            <Input
              type="text"
              name="type_police"
              value={formData.type_police}
              onChange={handleInputChange}
              required
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label htmlFor="numero_police">Numéro police</Label>
            <Input
              type="text"
              name="numero_police"
              value={formData.numero_police}
              onChange={handleInputChange}
              required
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label htmlFor="numero_sinistre">Numéro sinistre</Label>
            <Input
              type="text"
              name="numero_sinistre"
              value={formData.numero_sinistre}
              onChange={handleInputChange}
              required
            />
          </FieldWrapper>
        </FormGroup>
        <FormGroup>
          <FieldWrapper>
            <Label htmlFor="date_sinistre">Date sinistre</Label>
            <StyledDatePicker
              selected={formData.date_sinistre}
              onChange={(date) => handleDateChange("date_sinistre", date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Sélectionnez la date"
              required
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label htmlFor="date_declaration">Déclaration</Label>
            <StyledDatePicker
              selected={formData.date_declaration}
              onChange={(date) => handleDateChange("date_declaration", date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Sélectionnez la date"
              required
            />
          </FieldWrapper>
        </FormGroup>
        <FormGroup>
          <FieldWrapper>
            <Label htmlFor="statut_sinistre">Statut</Label>
            <Select
              options={statutOptions}
              value={statutOptions.find(
                (opt) => opt.value === formData.statut_sinistre
              )}
              onChange={(option) =>
                handleSelectChange("statut_sinistre", option)
              }
              required
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label htmlFor="nom_assureur">Nom Assureur</Label>
            <Select
              options={assureurOptions}
              value={assureurOptions.find(
                (opt) => opt.value === formData.nom_assureur
              )}
              onChange={(option) => handleSelectChange("nom_assureur", option)}
              required
            />
          </FieldWrapper>
        </FormGroup>
        <Button type="submit" disabled={isDisabled}>
          {isDisabled ? `Mise à jour en cours ${dots}` : "Mettre à jour"}
        </Button>
      </form>
      <Toaster />
    </FormCard>
  );
};

export default UpdateSinistrePopUp;
