import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  FaArrowLeft,
  FaPencilAlt,
  FaTrash,
  FaToggleOn,
  FaToggleOff,
  FaEye,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BASE_API_URL_ENDPOINT } from "../api/Api";
import DataTable from "../components/DataTable";
import { useSelector } from "react-redux";

const Container = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 24px;
  &:hover {
    color: #007bff;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  display: flex;
`;

const TabItem = styled.p`
  margin-left: 10px;
  padding: 5px;
  color: ${({ isActive }) => (isActive ? "#0056b3" : "#007BFF")};
  cursor: pointer;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? "#0056b3" : "transparent")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};

  &:hover {
    border-bottom-color: #007bff;
  }

  &:active {
    color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const Clients = () => {
  const [filterValue, setFilterValue] = useState("all");
  const [isActive, setIsActive] = useState(1);
  const [isUpdated, setIsUpdated] = useState(0);
  const isLoadingRef = useRef(false);
  const [data, setData] = useState([]);
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    const fetchData = async () => {
      // Éviter de relancer la requête si on est déjà en train de charger
      if (isLoadingRef.current) return;

      isLoadingRef.current = true; // Définir comme en cours de chargement

      try {
        const response = await axios.get(
          isActive === 1
            ? `${BASE_API_URL_ENDPOINT}clients`
            : `${BASE_API_URL_ENDPOINT}clients/unactive`
        );

        setData(response.data.data);
        toast.success("Chargement effectué !");
      } catch (error) {
        toast.error("Une erreur est survenue. Veuillez réessayer.");
      } finally {
        isLoadingRef.current = false; // Réinitialiser l'état de chargement
      }
    };

    fetchData();
  }, [isActive, isUpdated]);

  const activateClient = (id) => {
    axios
      .get(`${BASE_API_URL_ENDPOINT}activate/client/${id}`)
      .then((response) => {
        // Vérifie si la suppression a réussi
        if (response.data.state) {
          toast.success("Client activé avec succès.");
          setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
        }
      })
      .catch((error) => {
        toast.error("Erreur lors de la suppression de l'utilisateur:", error);
      });
  };

  const desactivateClient = (id) => {
    axios
      .get(`${BASE_API_URL_ENDPOINT}desactivate/client/${id}`)
      .then((response) => {
        // Vérifie si la suppression a réussi
        if (response.data.state) {
          toast.success("Client désactivé avec succès.");
          setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
        }
      })
      .catch((error) => {
        toast.error("Erreur lors de la suppression de l'utilisateur:", error);
      });
  };

  const deleteClient = (id) => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce client ?"
    );

    if (confirmation) {
      // Appeler l'API pour supprimer l'utilisateur en utilisant axios
      axios
        .get(`${BASE_API_URL_ENDPOINT}delete/client/${id}`)
        .then((response) => {
          // Vérifie si la suppression a réussi
          if (response.data.state) {
            toast.success("Client supprimé avec succès.");
            setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
          }
        })
        .catch((error) => {
          toast.error("Erreur lors de la suppression du client:", error);
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nom",
        accessor: (row) => {
          // Vérifie le type pour adapter l'affichage du nom
          return row.type === "Entreprise"
            ? `${row.content_json.company_name}`
            : `${row.content_json.client_name}`;
        },
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Détails",
        accessor: "detail",
        Cell: ({ row }) =>
          userData.rights.read ? (
            <Link to={`/client/${row.original.id}`}>
              <FaEye />
            </Link>
          ) : (
            <></>
          ),
      },
      {
        Header: "Modifier",
        accessor: "edit",
        Cell: ({ row }) =>
          userData.rights.write ? (
            <Link to={`/update/client/${row.original.id}`}>
              <FaPencilAlt />
            </Link>
          ) : (
            <></>
          ),
      },
      {
        Header: "Supprimer",
        accessor: "delete",
        Cell: ({ row }) =>
          userData.rights.delete ? (
            <Link onClick={() => deleteClient(row.original.id)}>
              <FaTrash color="#DA542E" />
            </Link>
          ) : (
            <></>
          ),
      },
      {
        Header: "Activer / Désactiver",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.active === 1 ? (
            <Link onClick={() => desactivateClient(row.original.id)}>
              <FaToggleOff color="#FFB848" />
            </Link>
          ) : (
            <Link onClick={() => activateClient(row.original.id)}>
              <FaToggleOn color="#7460EE" />
            </Link>
          ),
      },
    ],
    [data]
  );

  return (
    <Container>
      <Header>
        <SearchContainer>
          <BackButton to="/">
            <FaArrowLeft />
            <p style={{ marginLeft: "10px" }}>Clients</p>
          </BackButton>
          <Tab>
            <TabItem isActive={isActive === 1} onClick={() => setIsActive(1)}>
              Actifs
            </TabItem>
            <TabItem isActive={isActive === 2} onClick={() => setIsActive(2)}>
              Désactivés
            </TabItem>
          </Tab>
        </SearchContainer>
      </Header>
      <DataTable
        columns={columns}
        data={data}
        filterValue={filterValue}
        filterFieldIndex={1}
        onFilterChange={setFilterValue}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default Clients;
