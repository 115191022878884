// App.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  FaUserCircle,
  FaTh,
  FaEnvelope,
  FaIdBadge,
  FaSignOutAlt,
  FaChartPie,
} from "react-icons/fa";
import Home from "../section/Home";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/features/user/actions";
import User from "../section/User";
import Users from "../section/Users";
import Dashboard from "../section/Dashboard";
import Client from "../section/Client";
import Clients from "../section/Clients";
import Prospect from "../section/Prospect";
import Prospects from "../section/Prospects";
import Document from "../section/Document";
import Documents from "../section/Documents";
import Garantie from "../section/Garantie";
import Garanties from "../section/Garanties";
import Alertes from "../section/Alertes";
import Voyage from "../section/Voyage";
import Police from "../section/Police";
import ClientDetail from "../section/ClientDetail";
import ProspectDetail from "../section/ProspectDetail";
import SinistreDetail from "../section/SinistreDetail";
import ContratDetail from "../section/ContratDetail";

const Main = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    const handleClickOutside = () => {
      if (isUserMenuOpen) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isUserMenuOpen]);

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  return (
    <Router>
      <DashboardContainer>
        {/* En-tête */}
        <Header>
          <Logo>OKASSUR</Logo>
          <UserIcon onClick={handleMenuClick}>
            <FaUserCircle size={30} color="#fff" />
            {isUserMenuOpen && (
              <UserMenu onClick={(e) => e.stopPropagation()}>
                <MenuItem>
                  <FaIdBadge style={{ marginRight: 8 }} />
                  {userData.data.complete_name}
                </MenuItem>
                <MenuItem>
                  <FaEnvelope style={{ marginRight: 8 }} />
                  {userData.data.mail}
                </MenuItem>
                <MenuItem>
                  <FaTh style={{ marginRight: 8 }} />
                  {userData.type === 1 ? "Administrateur" : "Agent"}
                </MenuItem>
                <MenuItem onClick={logout}>
                  <FaSignOutAlt style={{ marginRight: 8 }} />
                  Déconnexion
                </MenuItem>
              </UserMenu>
            )}
          </UserIcon>
        </Header>

        {/* Icônes fixes */}
        <FixedHomeIcon to={"/"}>
          <FaTh size={24} />
        </FixedHomeIcon>
        {userData.rights.dashboard ? (
          <FixedDashboardIcon to={"dashboard"}>
            <FaChartPie size={24} />
          </FixedDashboardIcon>
        ) : (
          <></>
        )}

        {/* Conteneur principal */}
        <MainContainer>
          <Routes>
            <Route path="/" element={<Home />} />
            {userData.rights.dashboard ? (
              // <Route path="/dashboard" element={<Dashboard />} />
              <></>
            ) : (
              <></>
            )}
            {userData.type === 1 ? (
              <Route path="/user" element={<User />} />
            ) : (
              <></>
            )}
            {userData.type === 1 ? (
              <Route path="/users" element={<Users />} />
            ) : (
              <></>
            )}
            <Route path="/client" element={<Client />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/prospect" element={<Prospect />} />
            <Route path="/prospects" element={<Prospects />} />
            <Route path="/document" element={<Document />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/garantie" element={<Garantie />} />
            <Route path="/garanties" element={<Garanties />} />
            <Route path="/alertes" element={<Alertes />} />
            <Route path="/assvoyage" element={<Voyage />} />
            <Route path="/polvoyage" element={<Police />} />
            <Route path="/client/:id" element={<ClientDetail />} />
            <Route path="/prospect/:id" element={<ProspectDetail />} />
            <Route
              path="/sinistre/detail/:sinistre_id/:client_id"
              element={<SinistreDetail />}
            />
            <Route
              path="/contrat/detail/:contrat_id/:client_id"
              element={<ContratDetail />}
            />
          </Routes>
        </MainContainer>

        {/* Pied de page */}
        <Footer>Version: 1.2.5.092024</Footer>
      </DashboardContainer>
    </Router>
  );
};

export default Main;

// Styled Components
const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1f262d;
  padding: 10px 20px;
  height: 60px;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  box-sizing: border-box;
`;

const Logo = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  font-family: Arial, sans-serif;
`;

const UserIcon = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const UserMenu = styled.div`
  position: absolute;
  top: 35px;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  z-index: 10;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const MainContainer = styled.main`
  flex: 1;
  padding: 20px;
  background-color: #f4f6f8;
  overflow-y: auto;
`;

const FixedHomeIcon = styled(Link)`
  position: fixed;
  top: 70px; /* Positionné juste en dessous du header */
  left: 10px;
  background-color: #172169;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #fff;
    color: #172169;
  }
`;

const FixedDashboardIcon = styled(Link)`
  position: fixed;
  top: 70px;
  left: 70px;
  background-color: #42a23d;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #ffff;
    color: #42a23d;
  }
`;

const Footer = styled.footer`
  background-color: #1f262d;
  color: white;
  padding: 10px 20px;
  text-align: center;
  font-size: 14px;
  position: sticky;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
`;
