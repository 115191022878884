import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_API_DOCUMENT, BASE_API_URL_ENDPOINT } from "../api/Api";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import DataTable from "./DataTable";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  width: 100%;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 15px;
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ButtonSection = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  padding: 12px 20px;
  background: #172169;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #0056b3;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 10px;
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: 50%;
    max-width: 600px;
    padding: 30px;
    border-radius: 8px;

    @media (max-width: 768px) {
      width: 80%;
    }

    @media (max-width: 480px) {
      width: 95%;
      padding: 20px;
    }
  }
`;

const AvenantHPopUp = ({ isOpen, setIsOpen, data }) => {
  const [filterValue, setFilterValue] = useState("all");

  const columns = useMemo(
    () => [
      {
        Header: "Nom",
        accessor: "name",
      },
      {
        Header: "Consulter",
        accessor: "detail",
        Cell: ({ row }) => (
          <a href={`${BASE_API_DOCUMENT}${row.original.path}`} target="_blank">
            <FaEye />
          </a>
        ),
      },
    ],
    [data]
  );

  return (
    <StyledPopup open={isOpen} onClose={() => setIsOpen(false)} modal>
      <DataTable
        columns={columns}
        data={data}
        filterValue={filterValue}
        filterFieldIndex={0}
        onFilterChange={setFilterValue}
      />
    </StyledPopup>
  );
};

export default AvenantHPopUp;
