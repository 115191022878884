import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import axios from "axios";
import { FaPaperPlane, FaTimes } from "react-icons/fa";
import { BASE_API_URL_ENDPOINT } from "../api/Api";

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  background-color: #f5f5f5;
  width: 100%;
`;

const ChatList = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  max-height: calc(70vh - 120px);
`;

const ChatItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const ChatImg = styled.div`
  flex-shrink: 0;
  margin-right: 15px;
  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
`;

const ChatContent = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  max-width: 90%;
  word-break: break-word;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
`;

const Timestamp = styled.span`
  color: #999;
`;

const MessageText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  margin-left: 15px;
  &:hover {
    text-decoration: underline;
  }
`;

const Form = styled.form`
  display: flex;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  background-color: #ffffff;
`;

const Textarea = styled.textarea`
  flex: 1;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 10px;
  resize: none;
  height: 60px;
  font-size: 14px;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  margin-top: 15px;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px;
  &:hover {
    background-color: #0056b3;
  }
`;

const CommentaireComponent = ({ commentaires, client, setIsUpdated }) => {
  const userData = useSelector((state) => state.user.userData);
  const [editingIndex, setEditingIndex] = useState(null);
  const [commentValues, setCommentValues] = useState({});

  const handleEdit = async (index) => {
    if (editingIndex === index) {
      const updatedComment = commentValues[index];
      const success = await updateComment(client.id, index, updatedComment);
      if (success) {
        setIsUpdated((prev) => prev + 1);
      }
    }
    setEditingIndex(editingIndex === index ? null : index);
  };

  const handleDelete = async (index) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ? Cette opération est irréversible."
      )
    ) {
      const success = await deleteComment(client.id, index);
      if (success) {
        setIsUpdated((prev) => prev + 1);
      }
    }
  };

  const handleChange = (index, value) => {
    setCommentValues((prev) => ({ ...prev, [index]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("client_id", client.id);
    formData.append("user", JSON.stringify(userData));
    try {
      await axios.post(
        `${BASE_API_URL_ENDPOINT}process/comment/create`,
        formData
      );
      setIsUpdated((prev) => prev + 1);
    } catch (error) {
      setIsUpdated((prev) => prev + 1);
    } finally {
      event.target.reset();
    }
  };

  const deleteComment = async (clientId, index) => {
    const formData = new FormData();
    formData.append("client_id", clientId);
    formData.append("index", index);
    try {
      const response = await axios.post(
        `${BASE_API_URL_ENDPOINT}delete/prospect/comment`,
        formData
      );
      if (response.data.state) {
        setIsUpdated((prev) => prev + 1);
      }
    } catch (error) {
      setIsUpdated((prev) => prev + 1);
    }
    setIsUpdated((prev) => prev + 1);
  };

  const updateComment = async (clientId, index, value) => {
    const formData = new FormData();
    formData.append("client_id", clientId);
    formData.append("index", index);
    formData.append("value", value);
    formData.append("user", JSON.stringify(userData));

    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    try {
      const response = await axios.post(
        `${BASE_API_URL_ENDPOINT}update/prospect/comment`,
        formData
      );
      if (response.data.state) {
        setIsUpdated((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Error updating comment:", error);
      setIsUpdated((prev) => prev + 1);
    }
    setIsUpdated((prev) => prev + 1);
  };

  return (
    <ChatContainer>
      <ChatList>
        {commentaires.map((commentaire, index) => {
          const date = new Date(
            commentaire.updated
              ? commentaire.updated_value.date
              : commentaire.date
          );
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          };
          const formattedDate = date.toLocaleDateString("fr-FR", options);

          return (
            <ChatItem key={index}>
              <ChatImg>
                <img src="../../assets/images/users/1.jpg" alt="user" />
              </ChatImg>
              <ChatContent>
                <MessageHeader>
                  <span>{commentaire.user.data.complete_name}</span>
                  <Timestamp>
                    {commentaire.updated
                      ? `Modifié ${formattedDate} par ${commentaire.updated_value.user.data.complete_name}`
                      : formattedDate}
                  </Timestamp>
                </MessageHeader>
                {editingIndex === index ? (
                  <Textarea
                    value={commentValues[index] || commentaire.commentaire}
                    onChange={(e) => handleChange(index, e.target.value)}
                  />
                ) : (
                  <MessageText>{commentaire.commentaire}</MessageText>
                )}
                <Actions>
                  <ActionButton
                    onClick={() => handleEdit(index)}
                    title={editingIndex === index ? "Enregistrer" : "Modifier"}
                  >
                    {editingIndex === index ? "Enregistrer" : "Modifier"}
                  </ActionButton>
                  {userData.rights.delete && (
                    <ActionButton
                      onClick={() => handleDelete(index)}
                      title="Supprimer"
                    >
                      <FaTimes color="red" />
                    </ActionButton>
                  )}
                </Actions>
              </ChatContent>
            </ChatItem>
          );
        })}
      </ChatList>

      {userData.rights.write && (
        <Form onSubmit={handleSubmit}>
          <Textarea
            name="commentaire"
            placeholder="Votre commentaire..."
            required
          />
          <SubmitButtonWrapper>
            <SubmitButton type="submit">
              <FaPaperPlane />
            </SubmitButton>
          </SubmitButtonWrapper>
        </Form>
      )}
    </ChatContainer>
  );
};

export default CommentaireComponent;
