import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  FaArrowLeft,
  FaPencilAlt,
  FaTrash,
  FaToggleOn,
  FaToggleOff,
  FaEye,
  FaComment,
  FaSyncAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BASE_API_URL_ENDPOINT } from "../api/Api";
import DataTable from "../components/DataTable";
import { useSelector } from "react-redux";
import AlertPopUp from "../components/AlertPopUp";

const Container = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 24px;
  &:hover {
    color: #007bff;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  display: flex;
`;

const TabItem = styled.p`
  margin-left: 10px;
  padding: 5px;
  color: ${({ isActive }) => (isActive ? "#0056b3" : "#007BFF")};
  cursor: pointer;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? "#0056b3" : "transparent")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};

  &:hover {
    border-bottom-color: #007bff;
  }

  &:active {
    color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const Alertes = () => {
  const [filterValue, setFilterValue] = useState("all");
  const [isUpdated, setIsUpdated] = useState(0);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentData, setCommentData] = useState({ id: 0, client_id: 0 });
  const isLoadingRef = useRef(false);
  const [data, setData] = useState([]);
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    const fetchData = async () => {
      // Éviter de relancer la requête si on est déjà en train de charger
      if (isLoadingRef.current) return;

      isLoadingRef.current = true; // Définir comme en cours de chargement

      try {
        const response = await axios.get(`${BASE_API_URL_ENDPOINT}alertes`);

        setData(response.data.data);
        toast.success("Chargement effectué !");
      } catch (error) {
        toast.error("Une erreur est survenue. Veuillez réessayer.");
      } finally {
        isLoadingRef.current = false;
      }
    };

    fetchData();
  }, [isUpdated]);

  const handleClick = (id, client_id) => {
    setCommentData({ id: id, client_id: client_id });
    setIsCommentOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nom du client",
        accessor: "company_name",
      },
      {
        Header: "Branche",
        accessor: "branche",
      },
      {
        Header: "Numéro de police",
        accessor: "numero_police",
      },
      {
        Header: "Date d'échéance",
        accessor: "date_fin",
      },
      {
        Header: "Statut",
        accessor: "alerte_statut",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) =>
          userData.rights.write ? (
            <>
              <Link
                style={{ marginRight: "15px" }}
                onClick={() =>
                  handleClick(row.original.id, row.original.client_id)
                }
              >
                <FaComment color="#172169" />
              </Link>

              <Link
                to={`/contrat/detail/${row.original.id}/${row.original.client_id}`}
              >
                <FaSyncAlt color="#172169" />
              </Link>
            </>
          ) : (
            <></>
          ),
      },
    ],
    [data]
  );

  return (
    <Container>
      <Header>
        <SearchContainer>
          <BackButton to="/">
            <FaArrowLeft />
            <p style={{ marginLeft: "10px" }}>Alertes</p>
          </BackButton>
        </SearchContainer>
      </Header>
      <DataTable
        columns={columns}
        data={data}
        filterValue={filterValue}
        filterFieldIndex={4}
        onFilterChange={setFilterValue}
      />
      <AlertPopUp
        isOpen={isCommentOpen}
        setIsOpen={setIsCommentOpen}
        commentData={commentData}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default Alertes;
