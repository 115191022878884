import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaUser, FaLock } from "react-icons/fa";
import axios from "axios";
import { registerUser } from "../store/features/user/actions";
import { BASE_API_URL_ENDPOINT, headers } from "../api/Api";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

const path = window.location.pathname;
const pathSegments = path.split("/").filter((segment) => segment !== "");
const relativePrefix = "../".repeat(pathSegments.length);
const bgPath = `${relativePrefix}assets/images/background/okapi.png`;

// Composants stylisés
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(${bgPath});
  background-size: cover;
  background-position: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const LoginBox = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 380px;
  width: 90%;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 100px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;

const Icon = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  color: white;

  &.user {
    background-color: #172169;
  }

  &.lock {
    background-color: #42a23d;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: #172169;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 16px;
  }
`;

const VersionLabel = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 14px;
  text-align: center;
`;

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginSent, setLoginSent] = useState(false);
  const [dots, setDots] = useState("");
  const maxDots = 2;
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < maxDots ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const login = async () => {
    if (userName == "" && password == "") {
      toast.error("veuillez saisir vos identifiants");
      return;
    }
    // Configuration des données du formulaire
    const formData = new FormData();
    formData.append("username", `${userName}`);
    formData.append("password", `${password}`);

    // Envoi de la requête POST
    axios
      .post(`${BASE_API_URL_ENDPOINT}process/user/login`, formData, headers)
      .then((res) => {
        // Traitement en cas de succès
        if (res.data.state) {
          dispatch(registerUser(res.data.data));
        } else {
          // afficher le message d'erreur
          switch (res.data.code) {
            case "002":
              toast.error("les identifiants fournis sont incorrects");
              setLoginSent(false);
              break;
            case "003":
              toast.error("les identifiants fournis sont incorrects");
              setLoginSent(false);
              break;
            default:
              break;
          }
        }
      })
      .catch((err) => {
        // Gestion des erreurs
        setUserName("");
        setPassword("");
        setLoginSent(false);
        toast.error(err.message);
      });
  };

  return (
    <Container>
      <LoginBox>
        <Logo src={`${relativePrefix}assets/images/logo.png`} alt="Logo" />
        <InputContainer>
          <Icon className="user">
            <FaUser />
          </Icon>
          <Input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            disabled={loginSent}
            placeholder="Nom d'utilisateur"
          />
        </InputContainer>
        <InputContainer>
          <Icon className="lock">
            <FaLock />
          </Icon>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loginSent}
            placeholder="Mot de passe"
          />
        </InputContainer>
        <Button disabled={loginSent} onClick={login}>
          {`${loginSent ? `.${dots}` : `Connexion`}`}
        </Button>
      </LoginBox>
      <VersionLabel>Version: 1.2.5.092024</VersionLabel>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default Login;
