import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  FaArrowLeft,
  FaPencilAlt,
  FaTrash,
  FaToggleOn,
  FaToggleOff,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BASE_API_URL_ENDPOINT } from "../api/Api";
import DataTable from "../components/DataTable";
import { useSelector } from "react-redux";

const Container = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 24px;
  &:hover {
    color: #007bff;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  display: flex;
`;

const TabItem = styled.p`
  margin-left: 10px;
  padding: 5px;
  color: ${({ isActive }) => (isActive ? "#0056b3" : "#007BFF")};
  cursor: pointer;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? "#0056b3" : "transparent")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};

  &:hover {
    border-bottom-color: #007bff;
  }

  &:active {
    color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const Users = () => {
  const [filterValue, setFilterValue] = useState("all");
  const [isActive, setIsActive] = useState(1);
  const [isUpdated, setIsUpdated] = useState(0);
  const isLoadingRef = useRef(false);
  const [data, setData] = useState([]);
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    const fetchData = async () => {
      // Éviter de relancer la requête si on est déjà en train de charger
      if (isLoadingRef.current) return;

      isLoadingRef.current = true; // Définir comme en cours de chargement

      try {
        const response = await axios.get(
          isActive === 1
            ? `${BASE_API_URL_ENDPOINT}users`
            : `${BASE_API_URL_ENDPOINT}users/unactive`
        );

        setData(response.data.data);
        toast.success("Chargement effectué !");
      } catch (error) {
        toast.error("Une erreur est survenue. Veuillez réessayer.");
      } finally {
        isLoadingRef.current = false; // Réinitialiser l'état de chargement
      }
    };

    fetchData();
  }, [isActive, isUpdated]);

  const activateUser = (id) => {
    axios
      .get(`${BASE_API_URL_ENDPOINT}activate/user/${id}`)
      .then((response) => {
        // Vérifie si la suppression a réussi
        if (response.data.state) {
          toast.success("Utilisateur activé avec succès.");
          setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
        }
      })
      .catch((error) => {
        toast.error("Erreur lors de la suppression de l'utilisateur:", error);
      });
  };

  const desactivateUser = (id) => {
    axios
      .get(`${BASE_API_URL_ENDPOINT}desactivate/user/${id}`)
      .then((response) => {
        // Vérifie si la suppression a réussi
        if (response.data.state) {
          toast.success("Utilisateur désactivé avec succès.");
          setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
        }
      })
      .catch((error) => {
        toast.error("Erreur lors de la suppression de l'utilisateur:", error);
      });
  };

  const deleteUser = (id) => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
    );

    if (confirmation) {
      // Appeler l'API pour supprimer l'utilisateur en utilisant axios
      axios
        .get(`${BASE_API_URL_ENDPOINT}delete/user/${id}`)
        .then((response) => {
          // Vérifie si la suppression a réussi
          if (response.data.state) {
            toast.success("Utilisateur supprimé avec succès.");
            setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
          }
        })
        .catch((error) => {
          toast.error("Erreur lors de la suppression de l'utilisateur:", error);
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nom d'utilisateur",
        accessor: "username",
      },
      {
        Header: "Lecture",
        accessor: "rights.read",
        Cell: ({ row, value }) => (
          <input
            type="checkbox"
            checked={value}
            onChange={() => handleRightsChange(row.original.id, "read")}
          />
        ),
      },
      {
        Header: "Écriture",
        accessor: "rights.write",
        Cell: ({ row, value }) => (
          <input
            type="checkbox"
            checked={value}
            onChange={() => handleRightsChange(row.original.id, "write")}
          />
        ),
      },
      {
        Header: "Suppression",
        accessor: "rights.delete",
        Cell: ({ row, value }) => (
          <input
            type="checkbox"
            checked={value}
            onChange={() => handleRightsChange(row.original.id, "delete")}
          />
        ),
      },
      {
        Header: "Tableau de bord",
        accessor: "rights.dashboard",
        Cell: ({ row, value }) => (
          <input
            type="checkbox"
            checked={value}
            onChange={() => handleRightsChange(row.original.id, "dashboard")}
          />
        ),
      },
      {
        Header: "Commissions",
        accessor: "rights.commission",
        Cell: ({ row, value }) => (
          <input
            type="checkbox"
            checked={value}
            onChange={() => handleRightsChange(row.original.id, "commission")}
          />
        ),
      },
      {
        Header: "Rôle",
        accessor: "role",
      },
      {
        Header: "Nom complet",
        accessor: "complete_name",
      },
      {
        Header: "Adresse mail",
        accessor: "mail",
      },
      {
        Header: "Modifier",
        accessor: "edit",
        Cell: ({ row }) => (
          <Link to={`/user/${row.original.id}`}>
            <FaPencilAlt />
          </Link>
        ),
      },
      {
        Header: "Supprimer",
        accessor: "delete",
        Cell: ({ row }) =>
          userData.rights.delete ? (
            <Link onClick={() => deleteUser(row.original.id)}>
              <FaTrash color="#DA542E" />
            </Link>
          ) : (
            <></>
          ),
      },
      {
        Header: "Activer / Désactiver",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status === 1 ? (
            <Link onClick={() => desactivateUser(row.original.id)}>
              <FaToggleOff color="#FFB848" />
            </Link>
          ) : (
            <Link onClick={() => activateUser(row.original.id)}>
              <FaToggleOn color="#7460EE" />
            </Link>
          ),
      },
    ],
    [data]
  );

  const handleRightsChange = async (userId, rightType) => {
    // Mise à jour de l'état 'data' en fonction du changement de droits
    const updatedData = data.map((user) =>
      user.id === userId
        ? {
            ...user,
            rights: {
              ...user.rights,
              [rightType]: !user.rights[rightType],
            },
          }
        : user
    );
    setData(updatedData);

    // Trouver l'utilisateur mis à jour après la mise à jour de l'état
    const updatedUser = updatedData.find((user) => user.id === userId);

    if (!updatedUser) {
      console.error("Utilisateur non trouvé après mise à jour.");
      return;
    }

    // Création du FormData
    const formData = new FormData();
    formData.append("id", userId);
    formData.append("rights", JSON.stringify(updatedUser.rights));

    axios
      .post(`${BASE_API_URL_ENDPOINT}process/user/rights`, formData)
      .then((response) => {
        if (response.data.state) {
          toast.success(
            "Les droits ont été mis à jour avec succès pour cet utilisateur."
          );
        } else {
          toast.error("Une erreur est survenue lors de l'enregistrement.");
        }
      })
      .catch((error) => {
        toast.error("Error updating rights: " + error.message);
      });
  };

  return (
    <Container>
      <Header>
        <SearchContainer>
          <BackButton to="/">
            <FaArrowLeft />
            <p style={{ marginLeft: "10px" }}>Utilisateurs</p>
          </BackButton>
          <Tab>
            <TabItem isActive={isActive === 1} onClick={() => setIsActive(1)}>
              Actifs
            </TabItem>
            <TabItem isActive={isActive === 2} onClick={() => setIsActive(2)}>
              Désactivés
            </TabItem>
          </Tab>
        </SearchContainer>
      </Header>
      <DataTable
        columns={columns}
        data={data}
        filterValue={filterValue}
        filterFieldIndex={6}
        onFilterChange={setFilterValue}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default Users;
