// export const BASE_API_URL_ENDPOINT = "http://localhost/okassurApi/public/v1/";
// export const BASE_API_DOCUMENT = "http://localhost/okassurApi/uploads/";
export const BASE_API_URL_ENDPOINT = "https://okassur.org/api/public/v1/";
export const BASE_API_DOCUMENT = "https://okassur.org/api/uploads/";
export const ACCESS_AUTH_KEY = "40bd001563085fc35165329ea1ff5c5ecbdbbeef";
export const headers = {
  "Content-Type": "multipart/form-data",
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};
