import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  FaArrowLeft,
  FaPencilAlt,
  FaTrash,
  FaToggleOn,
  FaToggleOff,
  FaEye,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BASE_API_DOCUMENT, BASE_API_URL_ENDPOINT } from "../api/Api";
import DataTable from "../components/DataTable";
import { useSelector } from "react-redux";
import AddPjPopUp from "../components/AddPjPopUp";
import CommentaireComponent from "../components/CommentaireComponent";

const Container = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 24px;
  &:hover {
    color: #007bff;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  display: flex;
`;

const TabItem = styled.p`
  margin-left: 10px;
  padding: 5px;
  color: ${({ isActive }) => (isActive ? "#0056b3" : "#007BFF")};
  cursor: pointer;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? "#0056b3" : "transparent")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};

  &:hover {
    border-bottom-color: #007bff;
  }

  &:active {
    color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  background-color: #172169;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const ProspectDetail = () => {
  const [filterValue, setFilterValue] = useState("all");
  const [isActive, setIsActive] = useState(1);
  const [isUpdated, setIsUpdated] = useState(0);
  const [isOpenPj, setIsOpenPj] = useState(false);
  const isLoadingRef = useRef(false);
  const [client, setClient] = useState({});
  const [piecesJointes, setPiecesJointes] = useState([]);
  const [commentaires, setCommentaires] = useState([]);
  const userData = useSelector((state) => state.user.userData);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (isLoadingRef.current) return;

      isLoadingRef.current = true;

      try {
        const response = await axios.get(
          `${BASE_API_URL_ENDPOINT}prospect/${id}`
        );

        setClient(response.data.data.client_item);
        setPiecesJointes(response.data.data.piece_jointes);
        setCommentaires(response.data.data.commentaires);
        toast.success("Chargement effectué !");
      } catch (error) {
        toast.error("Une erreur est survenue. Veuillez réessayer.");
      } finally {
        isLoadingRef.current = false;
      }
    };

    fetchData();
  }, [isUpdated]);

  const deletePj = (index) => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cette piece jointe ?"
    );

    if (confirmation) {
      // Appeler l'API pour supprimer l'utilisateur en utilisant axios
      axios
        .get(`${BASE_API_URL_ENDPOINT}delete/pj/${client.id}/${index}`)
        .then((response) => {
          // Vérifie si la suppression a réussi
          if (response.data.state) {
            toast.success("Pièce jointe supprimée avec succès.");
            setIsUpdated((prevIsUpdated) => prevIsUpdated + 1);
          }
        })
        .catch((error) => {
          toast.error(
            "Erreur lors de la suppression de la pièce jointe:",
            error
          );
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nom",
        accessor: "name",
      },
      {
        Header: "Détails",
        accessor: "detail",
        Cell: ({ row }) =>
          userData.rights.read ? (
            <a
              href={`${BASE_API_DOCUMENT}${row.original.path}`}
              target="_blank"
            >
              <FaEye />
            </a>
          ) : (
            <></>
          ),
      },
      {
        Header: "Supprimer",
        accessor: "delete",
        Cell: ({ row }) =>
          userData.rights.delete ? (
            <Link
              onClick={() => {
                deletePj(row.index);
              }}
            >
              <FaTrash color="#DA542E" />
            </Link>
          ) : (
            <></>
          ),
      },
    ],
    [piecesJointes]
  );

  return (
    <Container>
      <Header>
        <SearchContainer>
          <BackButton to="/prospects">
            <FaArrowLeft />
            {client.type != undefined ? (
              <p style={{ marginLeft: "10px" }}>
                {client.type === 1
                  ? `${client.content_json.company_name} (Entreprise)`
                  : `${client.content_json.client_name} (Particulier)`}
              </p>
            ) : (
              <></>
            )}
          </BackButton>
          <Tab>
            <TabItem isActive={isActive === 1} onClick={() => setIsActive(1)}>
              Pièces jointes
            </TabItem>
            <TabItem isActive={isActive === 2} onClick={() => setIsActive(2)}>
              Commentaires
            </TabItem>
          </Tab>
        </SearchContainer>
      </Header>
      {isActive === 1 ? (
        <>
          <ButtonContainer>
            <Button
              onClick={() => {
                setIsOpenPj(true);
              }}
            >
              Ajoutez une pièce jointes
            </Button>
          </ButtonContainer>
          <DataTable
            columns={columns}
            data={piecesJointes}
            filterValue={filterValue}
            filterFieldIndex={0}
            onFilterChange={setFilterValue}
          />
        </>
      ) : (
        <CommentaireComponent
          setIsUpdated={setIsUpdated}
          commentaires={commentaires}
          client={client}
        />
      )}
      <AddPjPopUp
        isOpen={isOpenPj}
        setIsOpen={setIsOpenPj}
        setIsUpdated={setIsUpdated}
        client={client}
        endpoint={`process/pj/sinistre/create`}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default ProspectDetail;
