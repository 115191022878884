// App.js
import { useEffect, useState } from "react";
import Login from "./screens/Login";
import Main from "./screens/Main";
import { useSelector, useDispatch } from "react-redux";
import { checkUser, logoutUser } from "./store/features/user/actions";
import Loader from "./components/Loader";

function App() {
  const [loading, setLoading] = useState(true);
  const isUserConnected = useSelector((state) => state.user.isUserConnected);
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyUser = async () => {
      setLoading(true);
      // await dispatch(logoutUser());
      await dispatch(checkUser());
      setLoading(false);
    };

    verifyUser();
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  return <>{!isUserConnected ? <Login /> : <Main />}</>;
}

export default App;
